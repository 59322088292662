.ChoicesField {
  padding: 5px;
}
.ChoicesField .choiceWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 5px;
  margin-bottom: 5px;
  border: 1px dashed #454545;
  border-radius: 4px;
}
.ChoicesField .choiceWrapper > .TranslatableField {
  flex: 1;
}
.ChoicesField .choiceWrapper > button {
  flex: 0;
  margin-left: 5px;
}

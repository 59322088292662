/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.TasksListFilters .group-filters .btn:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  moz-border-top-left-radius: 0;
  moz-border-bottom-left-radius: 0;
  webkit-border-top-left-radius: 0;
  webkit-border-bottom-left-radius: 0;
}

@media print {
  .TasksListFilters .no-print {
    display: none;
  }
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.ArticlesList .articles-list .message {
  border: 1px solid #292929;
  padding: 6px 10px;
  font-size: 14px;
  text-decoration: none;
  width: 300px;
  max-width: 100%;
  text-align: center;
  margin: 0 auto;
  display: block;
}
.ArticlesList .articles-list .message i {
  margin-right: 5px;
}

.dark-app .ArticlesList .articles-list .message {
  background: #151515;
  color: #FFFFFF;
}

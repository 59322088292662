/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.InstanceSelector {
  width: 100%;
  margin: 0;
  border-radius: 0 !important;
  display: flex;
  border-top: 1px solid #757575;
  border-bottom: 1px solid #757575;
}
.InstanceSelector .pad-instance {
  color: #212529 !important;
  background: #C8C9CA !important;
  display: flex;
  align-items: center;
  min-width: 190px;
  height: 34px !important;
}
.InstanceSelector button {
  border-radius: 0% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #C8C9CA !important;
  color: #212529 !important;
  width: 30px;
  max-width: 30px;
  padding: 10px 0 10px 0 !important;
  margin: 0 !important;
}
.InstanceSelector button.custom-btn-width-collapsed {
  width: 45px !important;
  max-width: 45px !important;
}
.InstanceSelector .ui.selection.dropdown {
  min-width: 190px;
  border-radius: 0 !important;
}
.InstanceSelector .ui.selection.dropdown .text {
  width: 100%;
  height: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  color: #212529 !important;
  font-size: 13px;
  text-align: center;
}
.InstanceSelector .ui.selection.dropdown.dropdown-custom-width {
  min-width: calc(100% - 30px) !important;
}

.dark-app .InstanceSelector {
  border-top: 1px solid #212529;
  border-bottom: 1px solid #212529;
}
.dark-app .InstanceSelector .pad-instance {
  background: #212529 !important;
  color: #FFFFFF !important;
}
.dark-app .InstanceSelector button {
  background: #757575 !important;
  color: #FFFFFF !important;
}

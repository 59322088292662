/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.ChannelsList .ReactTable .rt-td {
  min-height: 60px;
}
.ChannelsList .ReactTable .rt-td .channel_cell .card-body {
  padding: 0;
}
.ChannelsList .ReactTable .rt-td .channel_cell .card-body .title-wrapper {
  background: rgba(0, 0, 0, 0.5);
  height: 52px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
}
.ChannelsList .ReactTable .rt-td .channel_cell .card-body .title-wrapper .card-title {
  width: 94%;
  white-space: normal;
  margin-bottom: 0;
  border-bottom: none;
  font-size: 16px;
  font-weight: bold;
}
.ChannelsList .ReactTable .rt-td .channel_cell .card-body .title-wrapper .card-title small {
  display: block;
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.TktSyncButton {
  font-size: 14px !important;
  padding: 5px 4px !important;
  width: 100% !important;
  border-radius: 0 !important;
  text-align: center !important;
  text-overflow: clip !important;
  max-height: 33px;
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.StatsForm .colStats {
  color: #C8C9CA;
  padding: 10px;
}
.StatsForm .rowStats {
  padding-left: 1%;
  padding-right: 1%;
}
.StatsForm #statsFormCard {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin: 0;
}
.StatsForm .statsFormPicker {
  width: 80% !important;
}
.StatsForm input {
  min-height: 2.7em;
  min-width: 150px;
}
.StatsForm .ui.selection.dropdown {
  height: 2.7em !important;
  width: 200px !important;
}
.StatsForm button.btn {
  height: 2.7em !important;
}
@media print {
  .StatsForm #statsFormCard {
    display: none !important;
  }
}

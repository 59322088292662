/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.TopBar {
  position: fixed;
  background-color: #FFFFFF;
  z-index: 101 !important;
}
.TopBar .logo {
  height: 40px;
  max-width: 100px;
}
.TopBar .collapse a.dropdown-toggle,
.TopBar .collapse a.dropdown-toggle:hover {
  color: #212529;
}
.TopBar .form-group {
  margin-bottom: 0;
}
.TopBar .form-group .form-control-sm {
  display: inline-block;
  background-color: #1A1A1A;
  color: #fff;
  border: 0;
  width: auto;
  height: auto;
  padding: 0.5rem 0;
  font-size: 14px;
}
.TopBar .settingsNav {
  background-color: #FFFFFF;
}
.TopBar .help-tooltips-button {
  margin-top: 3px;
  margin-right: 15px;
  height: 31px;
  font-size: 13px;
  font-style: italic;
}
.TopBar .btnNotActive {
  background-color: #FFFFFF !important;
  color: black !important;
  border: solid 0.5px #757575 !important;
}
.TopBar .btnActive {
  border: solid 0.5px #757575 !important;
  background-color: #f8af42 !important;
  color: #212529 !important;
}

.dark-app .TopBar {
  background-color: #1A1A1A;
}
.dark-app .TopBar .settingsNav {
  background-color: #171616;
}
.dark-app .collapse a.dropdown-toggle,
.dark-app .collapse a.dropdown-toggle:hover {
  color: #FFFFFF;
}
.dark-app .collapse .active {
  color: #212529;
  border: none;
}
.dark-app .collapse .active:not(.text-light) {
  background-color: #f8f9fa;
}
.dark-app .btnNotActive {
  background-color: #212529 !important;
  color: #f8f9fa !important;
  border: none;
}
.dark-app .btnActive {
  background-color: #FF9900 !important;
  color: #212529 !important;
}

.navbar-brand {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 1rem;
  background-color: rgb(255, 255, 255);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.25);
  width: 220px;
  text-align: center;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25);
}

.topBarBtn {
  width: 33% !important;
}

.topBarButtonGroup {
  width: 60% !important;
}

/*
 * Utilities
 */
.border-top {
  border-top: 1px solid #e5e5e5;
}

.border-bottom {
  border-bottom: 1px solid #e5e5e5;
}

@media screen and (max-width: 778px) {
  .TopBar .navbar-brand {
    width: 100px;
  }
  .TopBar .navbar-brand .logo {
    width: 50px;
  }
  .TopBar .collapse {
    display: block !important;
  }
  .TopBar .settingsNav {
    position: fixed !important;
    bottom: 0 !important;
    flex-direction: row;
    border-top: solid 0.5px #212529 !important;
    width: 100%;
    justify-content: space-between;
  }
  .TopBar .settingsNav > * {
    flex: 0 0 auto;
    align-items: center;
    margin: auto;
  }
  .TopBar .topBarButtonGroup {
    width: 100% !important;
  }
  .TopBar .topBarButtonGroup .btn {
    border-radius: 0;
  }
}

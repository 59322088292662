/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
@supports (position: -webkit-sticky) or (position: sticky) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}
.SideBar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: inherit !important;
  z-index: 100 !important;
  /* Behind the navbar */
  padding: 48px 0 0 !important;
  /* Height of navbar */
  box-shadow: inset -1px 0 0 #757575;
  background: #f8f9fa;
  /* Remove scrollbar on Chrome */
}
.SideBar ::-webkit-scrollbar {
  width: 0;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
}
.SideBar .sidebar-sticky {
  position: relative;
  top: 0;
  width: inherit !important;
  height: calc(100vh - 48px);
  overflow-x: hidden;
  overflow-y: auto;
  /* Remove scrollbar on FF and IE */
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.SideBar .sidebar-sticky .sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
  display: inline-block !important;
}
.SideBar .sidebar-sticky .nav-item {
  max-width: 100%;
  text-align: center;
  overflow: hidden;
  text-overflow: clip;
  /* tmp for the sidebar with items coming soon */
  /* tmp for the sidebar with items coming soon */
}
.SideBar .sidebar-sticky .nav-item .nav-link {
  font-weight: 500;
  color: #414141 !important;
  cursor: pointer;
  border-bottom: 1px solid #414141;
  display: flex;
  align-items: center;
}
.SideBar .sidebar-sticky .nav-item .nav-link i.fa {
  display: inline-block;
  width: 20px !important;
}
.SideBar .sidebar-sticky .nav-item .nav-link.btn.btn-secondary {
  background-color: #484848 !important;
  border: none;
  border-bottom: 1px solid #757575;
}
.SideBar .sidebar-sticky .nav-item .nav-link.btn.btn-secondary.active {
  background-color: #065461 !important;
  color: #fff !important;
}
.SideBar .sidebar-sticky .nav-item .nav-link.active {
  color: #414141 !important;
  background-color: #f8af42;
  border-right: 1px solid #414141;
}
.SideBar .sidebar-sticky .nav-item .nav-link.active .feather {
  color: inherit;
}
.SideBar .sidebar-sticky .nav-item .nav-link:hover:not(.active) {
  background-color: #F1F0F0;
  border-right: 1px solid #414141;
}
.SideBar .sidebar-sticky .nav-item .nav-link:hover:not(.active) .feather {
  color: inherit;
}
.SideBar .sidebar-sticky .nav-item ul.submenu {
  list-style-type: none;
  padding-left: 1rem;
}
.SideBar .collapsedSpan {
  display: none;
}
.SideBar .expandedSpan {
  display: inline-block;
}
@media (max-width: 767px) {
  .SideBar {
    width: 100% !important;
    z-index: 100 !important;
    padding-top: 80px !important;
  }
  .SideBar .sidebar-sticky {
    padding-bottom: 65px !important;
  }
  .SideBar .expandedSpan,
  .SideBar .collapsedSpan {
    display: inline-block;
  }
}

.dark-app .SideBar {
  box-shadow: inset -1px 0 0 rgba(255, 255, 255, 0.1);
  background: #1A1A1A;
}
.dark-app .SideBar .sidebar-sticky .nav-link {
  color: #FFFFFF !important;
  border-bottom: 1px solid #757575;
}
.dark-app .SideBar .sidebar-sticky .nav-link.active {
  background-color: #FF9900 !important;
  color: #212529 !important;
}
.dark-app .SideBar .sidebar-sticky .nav-link:hover:not(.active) {
  background-color: #2A2A2A;
}
.dark-app .SideBar .title-category {
  color: #ff9900;
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.DaysField .day-choices {
  display: flex;
  flex-direction: row;
  margin-bottom: 7px;
}
.DaysField .day-choices span.day-choice {
  flex: 1;
  border: 1px solid #212529;
  border-right: none;
  padding: 8px 0px 4px;
  box-sizing: content-box;
  background-color: #f8f9fa;
  color: #212529;
  display: inline-block;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
}
.DaysField .day-choices span.day-choice::first-child {
  border-radius: 4px 0 0 4px;
  moz-border-radius: 4px 0 0 4px;
  webkit-border-radius: 4px 0 0 4px;
}
.DaysField .day-choices span.day-choice::last-child {
  border-radius: 0 4px 4px 0;
  moz-border-radius: 0 4px 4px 0;
  webkit-border-radius: 0 4px 4px 0;
}
.DaysField .day-choices span.day-choice.active {
  background-color: #f8af42;
}
.DaysField .day-choices span.day-choice input {
  display: block;
  margin: 0 auto;
}
.DaysField .day-choices span.day-choice span.day {
  text-align: center;
}
.DaysField .day-choices span.day-choice:last-of-type {
  border-right: 0.5px solid #212529;
}

.dark-app .DaysField span.day-choice.active {
  background-color: #FF9900 !important;
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.ShiftForm .loader-wrapper {
  background: none !important;
  height: 100px;
}
.ShiftForm .loader-wrapper .Loader {
  margin: 0;
}
.ShiftForm .loader-wrapper .Loader .spinner-wrapper {
  max-width: 48px;
}
.ShiftForm .loader-wrapper .Loader .spinner-wrapper svg {
  width: 48px;
  height: 48px;
}
.ShiftForm .stop-at-wrapper .DateTimePicker .rdtPicker {
  right: 0;
}
.ShiftForm .remove-shift-wrapper {
  background: #FFFFFF;
  padding-left: 5px;
  border-radius: 0 6px 6px 0;
}
.ShiftForm .remove-shift-wrapper .btn:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ShiftForm .suggestions-tabs > li > a {
  font-size: 12px;
}
.ShiftForm .volunteer_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #212529;
  border-radius: 4px;
  cursor: pointer;
  padding: 3px;
}
.ShiftForm .volunteer_row:hover {
  background: rgba(0, 0, 0, 0.3);
}
.ShiftForm .volunteer_row .img_wrapper {
  width: 32px;
  height: 32px;
  margin: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ShiftForm .volunteer_row .img_wrapper img {
  width: 32px;
  height: 32px;
  border-radius: 32px;
}
.ShiftForm .volunteer_row .infos_wrapper {
  padding: 5px;
  font-weight: bold;
  font-size: 110%;
}
.ShiftForm .volunteer_row a {
  color: #FFFFFF !important;
  display: flex;
  flex-direction: column;
}
.ShiftForm .volunteer_row a .fullname {
  font-weight: bold;
}

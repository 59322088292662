/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.NavigationSettings {
  background: #1A1A1A;
  padding: 10px 20px;
  border-radius: 4px;
}
.NavigationSettings .card-title .btn {
  margin-top: -0.5rem;
}
.NavigationSettings .card.tab {
  height: auto;
  margin-bottom: 0.75rem;
}
.NavigationSettings .card.tab .drag-handle {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #FFFFFF;
  font-size: 20px;
  cursor: pointer;
}
.NavigationSettings .card.tab .card-title {
  text-align: center;
  font-size: 18px !important;
}
.NavigationSettings .card.tab .card-body {
  padding-top: 0.25rem;
}
.NavigationSettings .card.tab .card-footer {
  display: flex;
  justify-content: center;
}
.NavigationSettings .desc {
  font-style: italic;
}
.NavigationSettings .tab-chooser-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.85);
}
.NavigationSettings .tab-chooser-backdrop .tab-chooser {
  position: fixed;
  top: 50%;
  left: 50%;
  background: #2A2A2A;
  border: 1px solid #f8f9fa;
  padding: 20px;
  transform: translate(-50%, -50%);
}
.NavigationSettings .tab-chooser-backdrop .tab-chooser .close-btn {
  position: absolute;
  top: 0;
  right: 10px;
  color: #FFFFFF;
  font-size: 30px;
}
.NavigationSettings .tab-chooser-backdrop .tab-chooser .tab-choices {
  display: flex;
  flex-wrap: wrap;
}
.NavigationSettings .tab-chooser-backdrop .tab-chooser .tab-choices .tab-choice {
  flex: calc(25% - 10px);
  background: #1A1A1A;
  padding: 20px;
  margin: 5px;
  border: 1px solid #151515;
  cursor: pointer;
}
.NavigationSettings .tab-chooser-backdrop .tab-chooser .tab-choices .tab-choice:hover {
  background: #151515;
  border-color: #FF9900;
}
.NavigationSettings .tab-chooser-backdrop .tab-chooser .tab-choices .tab-choice:hover .tab-choice-title {
  color: #FF9900;
}
.NavigationSettings .tab-chooser-backdrop .tab-chooser .tab-choices .tab-choice:hover .btn {
  color: #FF9900;
}
.NavigationSettings .tab-chooser-backdrop .tab-chooser .tab-choices .tab-choice .tab-choice-title {
  font-weight: bold;
  text-align: center;
}

body > .col-sm-3 > .card {
  background-color: rgba(0, 0, 0, 0.25);
  height: 185px;
}
body > .col-sm-3 > .card * {
  display: none;
}

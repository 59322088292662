/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.ModalComponent .card-title .btn {
  margin-top: -0.5rem;
}
.ModalComponent .card.tab {
  height: auto;
  margin-bottom: 0.75rem;
}
.ModalComponent .card.tab .drag-handle {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #FFFFFF;
  font-size: 20px;
  cursor: pointer;
}
.ModalComponent .card.tab .card-title {
  text-align: center;
  font-size: 18px !important;
}
.ModalComponent .card.tab .card-body {
  padding-top: 0.25rem;
}
.ModalComponent .card.tab .card-footer {
  display: flex;
  justify-content: center;
}
.ModalComponent .desc {
  font-style: italic;
}
.ModalComponent .modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.85);
}
.ModalComponent .modal-backdrop .modal-chooser {
  position: fixed;
  top: 50%;
  left: 50%;
  background: #2A2A2A;
  border: 1px solid #f8f9fa;
  padding: 20px;
  transform: translate(-50%, -50%);
}
.ModalComponent .modal-backdrop .modal-chooser .close-btn {
  position: absolute;
  top: 0;
  right: 10px;
  color: #FFFFFF;
  font-size: 30px;
}
.ModalComponent .modal-backdrop .modal-chooser .modal-choices {
  display: flex;
  flex-wrap: wrap;
}

body > .col-sm-3 > .card {
  background-color: rgba(0, 0, 0, 0.25);
  height: 185px;
}
body > .col-sm-3 > .card * {
  display: none;
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.Planner .rbc-calendar {
  height: calc(100vh - 200px);
  background: #FFFFFF;
  color: #212529;
}
.Planner .rbc-calendar .rbc-toolbar button {
  background-color: #F3F3F3 !important;
}
.Planner .rbc-calendar .rbc-event.empty {
  background-color: #cb7c7c;
  border-color: #bd5757;
}
.Planner .rbc-calendar .rbc-event.filling {
  background-color: #7cbacb;
  border-color: #57a7bd;
}
.Planner .rbc-calendar .rbc-event.full {
  background-color: #7ccb7c;
  border-color: #57bd57;
}
.Planner .event-details-wrapper {
  position: fixed;
  z-index: 100;
  top: 50px;
  right: 0;
  width: 450px;
  max-width: 100%;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0px 5px 5px 2px #000000;
}
.Planner .event-details-wrapper .event-details-closer {
  position: absolute;
  top: 20px;
  right: 20px;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
}
.Planner .event-details-wrapper .event-details-content {
  margin: 10px;
  padding: 10px;
  color: #212529;
  background: #DCDCDC;
  background: rgba(255, 255, 255, 0.95);
  width: calc(100% - 20px);
  max-height: calc(100vh - 80px);
  border-radius: 4px;
  overflow: auto;
  overflow-x: hidden;
}
.Planner .event-details-wrapper .event-details-content h4 {
  font-size: 20px;
}
.Planner .event-details-wrapper .event-details-content h4:first-child {
  margin-right: 30px;
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.LastEvent .settingsFormInput {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.LastEvent .settingsFormLabel {
  height: 40px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.LastEvent .settingsFormLabel div:first-of-type {
  width: 70%;
}
.LastEvent .settingsLabelRight {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.LastEvent .settingsInput {
  min-width: 0;
  width: 250px;
}
.LastEvent .submitButtons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.LastEvent .submitButtons Button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6vw;
  font-size: 0.75vw;
}
.LastEvent .cardWrapper {
  width: 100%;
}
.LastEvent .cardWrapper h4 {
  max-height: 30px;
  padding: 0;
  border-bottom: 1px solid #757575;
}
.LastEvent .cardWrapper .cardGrp {
  width: 100%;
  margin-top: 10px;
}
.LastEvent .cardWrapper .cardGrp .title {
  border: none !important;
  padding: 5px;
  margin: 0;
  font-size: 16px !important;
}
.LastEvent .cardWrapper .cardGrp .card {
  border: 1px solid #757575;
  border-radius: 0 0 0 0;
}
.LastEvent .cardWrapper .cardGrp .card .cardPic {
  object-fit: cover;
  border-radius: 0 0 0 0;
  border-bottom: 1px solid #757575;
  width: 100%;
  height: 200px;
}
.LastEvent .cardWrapper .cardGrp .card .linkBtn {
  width: 50%;
}
.LastEvent .cardWrapper .cardGrp .card .linkBtn Button {
  width: 100%;
}
.LastEvent .cardWrapper .cardGrp .card .noPicWrapper {
  height: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  border-bottom: 1px solid #757575;
  color: #FFFFFF;
}
.LastEvent .cardWrapper .cardGrp :first-child {
  padding-left: 0;
}
.LastEvent .cardWrapper .cardGrp :last-child {
  padding-right: 0;
}
@media screen and (max-width: 767px) {
  .LastEvent .cardWrapper .cardGrp :first-child {
    padding-left: 15px;
  }
  .LastEvent .cardWrapper .cardGrp :last-child {
    padding-right: 15px;
  }
}
.LastEvent .noComputeWrapper {
  height: 200px;
}
.LastEvent .noComputeWrapper .noCompute {
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.LastEvent .noComputeWrapper .noCompute h1 {
  display: block;
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.ScreenWidgetsSettings .card-title .btn {
  margin-top: -0.5rem;
}
.ScreenWidgetsSettings .card.widget {
  height: auto;
  margin-bottom: 0;
}
.ScreenWidgetsSettings .card.widget .drag-handle {
  position: absolute;
  top: 12px;
  left: 10px;
  color: #FFFFFF;
  font-size: 20px;
  cursor: pointer;
}
.ScreenWidgetsSettings .card.widget .card-title {
  font-size: 18px !important;
  border-bottom: none !important;
  padding-left: 40px;
  padding-bottom: 0;
  margin-bottom: 0;
}
.ScreenWidgetsSettings .card.widget .card-title small {
  font-style: italic;
}
.ScreenWidgetsSettings .card.widget .card-title .btn-group {
  margin-top: 3px;
  margin-right: 8px;
}
.ScreenWidgetsSettings .card.widget .card-body {
  padding-top: 0.25rem;
}
.ScreenWidgetsSettings .card.widget .card-footer {
  display: flex;
  justify-content: center;
}
.ScreenWidgetsSettings .desc {
  font-style: italic;
}
.ScreenWidgetsSettings .widget-chooser-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.85);
}
.ScreenWidgetsSettings .widget-chooser-backdrop .widget-chooser {
  position: fixed;
  top: 50%;
  left: 50%;
  background: #2A2A2A;
  border: 1px solid #f8f9fa;
  padding: 20px;
  transform: translate(-50%, -50%);
}
.ScreenWidgetsSettings .widget-chooser-backdrop .widget-chooser .close-btn {
  position: absolute;
  top: 0;
  right: 10px;
  color: #FFFFFF;
  font-size: 30px;
}
.ScreenWidgetsSettings .widget-chooser-backdrop .widget-chooser .widget-choices {
  display: flex;
  flex-wrap: wrap;
}
.ScreenWidgetsSettings .widget-chooser-backdrop .widget-chooser .widget-choices .widget-choice {
  flex: calc(25% - 10px);
  background: #1A1A1A;
  padding: 20px;
  margin: 5px;
  border: 1px solid #151515;
  cursor: pointer;
}
.ScreenWidgetsSettings .widget-chooser-backdrop .widget-chooser .widget-choices .widget-choice:hover {
  background: #151515;
  border-color: #FF9900;
}
.ScreenWidgetsSettings .widget-chooser-backdrop .widget-chooser .widget-choices .widget-choice:hover .widget-choice-title {
  color: #FF9900;
}
.ScreenWidgetsSettings .widget-chooser-backdrop .widget-chooser .widget-choices .widget-choice:hover .btn {
  color: #FF9900;
}
.ScreenWidgetsSettings .widget-chooser-backdrop .widget-chooser .widget-choices .widget-choice .widget-choice-title {
  font-weight: bold;
  text-align: center;
}
.ScreenWidgetsSettings .widget-chooser-backdrop .widget-chooser .widget-choices .widget-choice .widget-choice-desc {
  font-style: italic;
  font-size: 0.9rem;
  text-align: center;
}
.ScreenWidgetsSettings .widget-editor-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.85);
}
.ScreenWidgetsSettings .widget-editor-backdrop .widget-editor {
  position: fixed;
  top: 50%;
  left: 50%;
  background: #2A2A2A;
  border: 1px solid #f8f9fa;
  padding: 20px;
  transform: translate(-50%, -50%);
  width: 50vw;
  min-width: 450px;
  max-height: 80vh;
}
.ScreenWidgetsSettings .widget-editor-backdrop .widget-editor .close-btn {
  position: absolute;
  top: -10px;
  right: -5px;
  color: #FFFFFF;
  font-size: 30px;
}
.ScreenWidgetsSettings .widget-editor-backdrop .widget-editor .widget-settings {
  max-height: calc(80vh - 100px);
  overflow: auto;
}
.ScreenWidgetsSettings .widget-editor-backdrop .widget-editor .widget-settings .widget-setting {
  background: #1A1A1A;
  padding: 10px;
  margin: 5px;
  border: 1px solid #151515;
}
.ScreenWidgetsSettings .widget-editor-backdrop .widget-editor .widget-settings .widget-setting:hover {
  background: #151515;
}
.ScreenWidgetsSettings .widget-editor-backdrop .widget-editor .widget-settings .widget-setting label {
  display: flex;
  align-items: center;
}

body > .col-sm-12 > .card {
  background-color: rgba(0, 0, 0, 0.25);
  min-height: 50px;
}
body > .col-sm-12 > .card.widget * {
  display: none;
}

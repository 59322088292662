/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.Nav .expandedNav {
  animation: expandNav 0.2s linear 0s normal forwards;
  text-align: left !important;
}
.Nav .reducedNav {
  animation: reduceNav 0.2s linear 0s normal forwards;
  text-align: center !important;
}
@keyframes expandNav {
  0% {
    width: 45px;
  }
  100% {
    width: 220px;
  }
}
@keyframes reduceNav {
  0% {
    width: 220px;
  }
  100% {
    width: 45px;
  }
}
.Nav .sidebar-btn {
  background-color: #C8C9CA;
  color: #212529;
  border: none;
}
@media (max-width: 767px) {
  .Nav .sidebar-btn {
    display: none;
  }
  .Nav .navbar-toggler {
    top: 10px;
    right: 10px;
    z-index: 10000;
    background-color: #f8f9fa;
    color: #f8af42;
    border-color: #171616;
  }
  .Nav .navbar-toggler:hover {
    background-color: #212529;
    color: #f8af42;
    border-color: #171616;
  }
}

.dark-app .Nav .sidebar-btn {
  background: #757575 !important;
  color: #FFFFFF !important;
}
.dark-app .Nav .navbar-toggler {
  background-color: #212529;
  color: #FF9900;
  border-color: #f8f9fa;
}
.dark-app .Nav .navbar-toggler:hover {
  background-color: #f8f9fa;
  color: #FF9900;
  border-color: #f8f9fa;
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.ItemsList .list-wrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
}
.ItemsList .list-wrapper .responsive-itemslist {
  width: 100%;
}
.ItemsList .list-wrapper .responsive-itemslist button, .ItemsList .list-wrapper .responsive-itemslist select {
  height: 40px !important;
}
.ItemsList .list-wrapper .responsive-itemslist.editable select {
  width: calc(100% - 40px);
  border-radius: 0 0 0 4px;
  moz-border-radius: 0 0 0 4px;
  webkit-border-radius: 0 0 0 4px;
}
.ItemsList .list-wrapper .responsive-itemslist.editable button {
  width: 40px;
  border-radius: 0 0 4px 0;
  moz-border-radius: 0 0 4px 0;
  webkit-border-radius: 0 0 4px 0;
}
.ItemsList .list-wrapper .list-title {
  padding: 5px;
  border: 1px solid #292929;
  border-bottom: none;
  text-align: center;
  font-weight: bold;
  border-radius: 4px 4px 0 0;
  moz-border-radius: 4px 4px 0 0;
  webkit-border-radius: 4px 4px 0 0;
  background-color: #F1F0F0;
}
.ItemsList .list-wrapper .list-item {
  cursor: pointer;
  flex: 1;
  padding: 5px 10px;
  border: 1px solid #292929;
  border-bottom: none;
  display: flex;
  align-items: baseline;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline;
  position: relative;
}
.ItemsList .list-wrapper .list-item > i {
  width: 24px;
  padding-right: 10px;
  text-align: center;
}
.ItemsList .list-wrapper .list-item.depth-1 > i {
  padding-left: 10px;
  padding-right: 20px;
}
.ItemsList .list-wrapper .list-item.depth-2 > i {
  padding-left: 20px;
  padding-right: 20px;
}
.ItemsList .list-wrapper .list-item.depth-3 > i {
  padding-left: 30px;
  padding-right: 20px;
}
.ItemsList .list-wrapper .list-item.depth-4 > i {
  padding-left: 40px;
  padding-right: 20px;
}
.ItemsList .list-wrapper .list-item.depth-5 > i {
  padding-left: 50px;
  padding-right: 20px;
}
.ItemsList .list-wrapper .list-item > img {
  width: 25px;
  height: 25px;
  object-fit: contain;
  padding: 1px;
  margin-right: 10px;
  background: #FFFFFF;
  display: inline-block;
  border-radius: 4px;
}
.ItemsList .list-wrapper .list-item:last-child {
  border-bottom: 1px solid #292929;
  border-radius: 0 0 4px 4px;
  moz-border-radius: 0 0 4px 4px;
  webkit-border-radius: 0 0 4px 4px;
}
.ItemsList .list-wrapper .list-item:hover {
  background: #C8C9CA;
  color: #212529;
}
.ItemsList .list-wrapper .list-item.active {
  color: #222222;
  background: #f8af42;
}
.ItemsList.horizontal {
  width: 100%;
}
.ItemsList.horizontal .list-wrapper {
  flex-direction: row;
  padding-bottom: 15px;
}
.ItemsList.horizontal .list-title {
  border-bottom: 1px solid #292929;
  border-right: none;
  border-radius: 4px 0 0 4px;
  moz-border-radius: 4px 0 0 4px;
  webkit-border-radius: 4px 0 0 4px;
}
.ItemsList.horizontal .list-item {
  padding: 10px;
  border: 1px solid #292929;
  border-right: none;
}
.ItemsList.horizontal .list-item:first-child {
  border-radius: 4px 0 0 4px;
  moz-border-radius: 4px 0 0 4px;
  webkit-border-radius: 4px 0 0 4px;
}
.ItemsList.horizontal .list-item:last-child {
  border-right: 1px solid #292929;
  border-radius: 0 4px 4px 0;
  moz-border-radius: 0 4px 4px 0;
  webkit-border-radius: 0 4px 4px 0;
}

.dark-app .ItemsList .list-wrapper .list-title {
  background: #151515;
  color: white;
}
.dark-app .ItemsList .list-wrapper .list-item {
  background: #171616;
  color: white;
}
.dark-app .ItemsList .list-wrapper .list-item:hover {
  background: #292929;
}
.dark-app .ItemsList .list-wrapper .list-item.active {
  color: #222222;
  background: #FF9900;
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.Map {
  overflow: auto;
}
.Map .bi.bi-diagram {
  background-color: #2A2A2A;
  border-color: #444444;
}
.Map .bi.bi-diagram .bi-diagram-node {
  background: transparent !important;
  border-width: 0 !important;
  padding: 0 !important;
}
.Map .bi.bi-diagram .bi-diagram-node .HostResume .card-header {
  background-color: #151515;
}
.Map .bi.bi-diagram .bi-diagram-node .HostResume .card-header .card-title {
  font-weight: bold;
  font-size: 1.25rem;
}
.Map .bi.bi-diagram .bi-diagram-node .card-title {
  color: #FFFFFF;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: auto;
  padding-right: 5px;
}
.Map .bi.bi-diagram .bi-diagram-link .bi-link-path {
  stroke-dasharray: 10, 2;
}
.Map .bi.bi-diagram .bi-diagram-link.connected .bi-link-path {
  stroke: #88ff98;
  animation: BiDashSegmentAnimation 1s linear infinite;
}
.Map .bi.bi-diagram .bi-diagram-link.disconnected .bi-link-path {
  stroke: #ff8888;
}

.DragAndResize {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  display: inline-flex;
  vertical-align: top;
}
.DragAndResize .top_buttons {
  position: absolute;
  top: -40px;
  left: -1px;
  right: 0px;
  display: none;
  cursor: pointer;
  min-width: 70px;
  background: rgba(136, 136, 136, 0.5333333333);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}
.DragAndResize .top_buttons .top_button {
  border: 1px solid rgba(255, 255, 255, 0.5333333333);
  padding: 0 5px;
  border-radius: 4px;
  background: #222222;
}
.DragAndResize .top_buttons .top_button.delete_button {
  font-size: 16px;
}
.DragAndResize .top_buttons .top_button.edit_button {
  font-size: 16px;
}
.DragAndResize .rnd > div:first-child {
  border: 1px dashed #222222;
}
.DragAndResize.active .rnd > div:first-child {
  border: 1px solid #FF9725;
  box-shadow: 0 0 10px 1px #FF9725;
}
.DragAndResize .col {
  margin-bottom: 10px;
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.DateTimePicker .date-picker {
  width: calc(100% - 36px) !important;
}
.DateTimePicker .rdt > input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.DateTimePicker .rdtPicker {
  width: 300px;
}

.dark-app .DateTimePicker .rdtPicker {
  background: #171616;
}

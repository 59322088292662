/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.ShowWidgetList .addWidget {
  display: block;
  text-decoration: none !important;
  overflow: hidden;
  text-align: end;
  color: #FFFFFF;
}
.ShowWidgetList .addWidget :link, .ShowWidgetList .addWidget .shortcut:visited, .ShowWidgetList .addWidget .shortcut:hover, .ShowWidgetList .addWidget .shortcut:active {
  color: #FFFFFF;
}
.ShowWidgetList .addWidget .textDiv {
  width: 80% !important;
  height: 100%;
  text-align: right;
}
.ShowWidgetList .addWidget .textDiv h2, .ShowWidgetList .addWidget .textDiv h4 {
  display: block;
  min-width: 80%;
}
.ShowWidgetList .addWidget i {
  display: block;
  position: absolute;
  font-size: 12vw;
  color: #757575;
  top: 25px;
  left: 30px;
  z-index: 0;
  opacity: 0.4;
}

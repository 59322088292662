/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.PdfLayouts .LanguageSelector {
  margin-left: 10px;
}
.PdfLayouts .topButtonsWrapper {
  padding: 7px;
  margin-bottom: 5px;
  border-radius: 4px 4px 0 0;
  background-color: #151515;
  width: calc(100% - 20px);
}
.PdfLayouts .dataTestButtonsWrapper {
  padding: 7px;
  margin-bottom: 5px;
  background-color: #151515;
  width: calc(100% - 20px);
}
.PdfLayouts .dataTestButtonsWrapper label {
  margin: 0 5px;
}
.PdfLayouts .rangeWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  height: 32px;
  background: #484848;
  border-radius: 4px;
}
.PdfLayouts .rangeWrapper .range {
  width: calc(100% - 70px);
  padding: 5px;
  display: flex;
  align-items: center;
}
.PdfLayouts .rangeWrapper .range i {
  margin: 0 5px;
}
.PdfLayouts .rangeWrapper .range input {
  cursor: pointer;
}
.PdfLayouts .rangeWrapper .rangeInfos {
  width: 68px;
  height: calc(100% - 4px);
  background: #151515;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 90%;
  padding-right: 5px;
}
.PdfLayouts .rangeWrapper .rangeInfos input {
  background: #484848;
  color: #FFFFFF;
  border: 1px solid #151515;
  padding: 0;
  margin: 0 2px;
  text-align: center;
  font-weight: bold;
  display: inline-block;
}
.PdfLayouts .chrome-picker {
  max-width: 100%;
}
.PdfLayouts .previewWrapper {
  padding: 10px;
  background-color: #151515;
  width: calc(100% - 20px);
  max-height: calc(100vh - 140px);
  overflow: auto;
  border-radius: 0 0 4px 4px;
}
.PdfLayouts .previewWrapper .preview {
  display: inline-block;
  position: relative;
  background-color: #FFFFFF;
  box-shadow: 0 0 10px 1px #212529;
}
.PdfLayouts .previewWrapper .preview p {
  line-height: inherit;
}
.PdfLayouts .widgetBtn {
  width: calc(100% - 10px);
  min-height: 40px;
  margin-right: 20px;
  margin-bottom: 10px;
  background-color: #ffad33;
  color: #212529;
  position: relative;
}
.PdfLayouts .widgetBtn:hover, .PdfLayouts .widgetBtn:focus, .PdfLayouts .widgetBtn:active {
  background-color: #FF9900;
  color: #212529;
}
.PdfLayouts .widgetBtn:nth-child(2n) {
  margin-right: 0;
}
.PdfLayouts .widgetBtn i {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translate(0, -50%);
  font-size: 20px;
}
.PdfLayouts .imageOption {
  width: 100%;
  height: 120px;
  background-color: #252525;
}
.PdfLayouts .imageOption img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.PdfLayouts .imageOption .file_input {
  width: 100%;
  height: 120px;
  opacity: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}
.PdfLayouts .widgetsLists {
  max-height: 300px;
  overflow: auto;
}
.PdfLayouts .pdfLinkedToTicketTypes .list-title,
.PdfLayouts .pdfLinkedToTicketTypes .list-item {
  background-color: transparent !important;
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.TasksList {
  padding-left: 40px;
}

@media screen and (max-width: 920px) {
  .TasksList {
    padding-left: 0;
  }
}

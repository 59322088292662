/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.TasksListDetails ul.tasks-ul {
  list-style-type: none;
  padding-left: 0;
}
.TasksListDetails ul.tasks-ul > li {
  margin-bottom: 30px;
}
.TasksListDetails ul.tasks-ul > li h3 {
  border-bottom: 1px solid #212529;
  margin: 20px 0;
  text-transform: uppercase;
  font-size: 1.5rem;
}
.TasksListDetails ul.tasks-ul > li ul {
  list-style-type: none;
  padding-left: 0;
}
.TasksListDetails ul.tasks-ul > li ul li {
  margin-bottom: 5px;
  border-top: 1px solid #444444;
  border-bottom: 1px solid #444444;
  padding: 5px 0;
}
.TasksListDetails ul.tasks-ul > li ul li > span {
  display: inline-block;
  min-width: 140px;
  font-weight: bold;
  text-align: center;
  background: #F1F0F0;
  border: 1px solid #212529;
  padding: 5px;
}
.TasksListDetails ul.tasks-ul > li ul li span.activity {
  background: #F1F0F0;
  padding: 5px 5px;
  border-radius: 0.2rem 0 0 0.2rem;
  moz-border-radius: 0.2rem 0 0 0.2rem;
  webkit-border-radius: 0.2rem 0 0 0.2rem;
  text-align: left;
  text-indent: 10px;
}
.TasksListDetails ul.tasks-ul > li ul li span.admins {
  background: #F1F0F0;
  padding: 5px 5px;
  border-radius: 0.2rem;
  moz-border-radius: 0.2rem;
  webkit-border-radius: 0.2rem;
  text-align: left;
  text-indent: 10px;
}
.TasksListDetails ul.tasks-ul > li ul li span.user {
  background: #F1F0F0;
  text-align: left;
  text-indent: 5px;
}
.TasksListDetails ul.tasks-ul > li ul li span.user.assign {
  padding: 0;
}
.TasksListDetails ul.tasks-ul > li ul li .status.change {
  background-color: #f8f9fa;
  border: none;
}
.TasksListDetails ul.tasks-ul > li ul li .buttons {
  margin-left: 20px;
}
.TasksListDetails ul.tasks-ul > li ul li .buttons .btn:first-child {
  border-top-left-radius: 0;
  moz-border-top-left-radius: 0;
  webkit-border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  moz-border-bottom-left-radius: 0;
  webkit-border-bottom-left-radius: 0;
}

@media screen and (max-width: 920px) {
  .TasksListDetails ul.tasks-ul > li h3 {
    text-align: center;
  }
  .TasksListDetails ul.tasks-ul > li ul li {
    margin-bottom: 30px;
    border-left: 1px solid #444444;
    border-right: 1px solid #444444;
    padding: 5px;
  }
  .TasksListDetails ul.tasks-ul > li ul li > span {
    display: block;
    width: 100%;
  }
  .TasksListDetails ul.tasks-ul > li ul li span.activity,
  .TasksListDetails ul.tasks-ul > li ul li span.admins {
    text-align: center;
  }
  .TasksListDetails ul.tasks-ul > li ul li span.user {
    text-align: center;
    text-indent: 0;
  }
  .TasksListDetails ul.tasks-ul > li ul li .buttons {
    margin-left: 0;
    display: flex;
    flex-direction: row;
  }
  .TasksListDetails ul.tasks-ul > li ul li .buttons .btn {
    flex: 1;
    border: 1px solid #212529;
    height: 34px;
  }
  .TasksListDetails ul.tasks-ul > li ul li .buttons .btn:first-child {
    border-bottom-left-radius: 0.2rem;
    moz-border-bottom-left-radius: 0.2rem;
    webkit-border-bottom-left-radius: 0.2rem;
  }
  .TasksListDetails ul.tasks-ul > li ul li .buttons .btn:first-child {
    border-bottom-right-radius: 0.2rem;
    moz-border-bottom-right-radius: 0.2rem;
    webkit-border-bottom-right-radius: 0.2rem;
  }
}
@media print {
  .TasksListDetails ul.tasks-ul {
    padding-left: 0;
  }
  .TasksListDetails ul.tasks-ul > li {
    page-break-inside: avoid;
  }
  .TasksListDetails ul.tasks-ul > li h3 {
    margin: 20px 0 0 0;
  }
  .TasksListDetails ul.tasks-ul > li ul li {
    border-left: 1px solid #444444;
    border-right: 1px solid #444444;
    padding: 0;
  }
  .TasksListDetails ul.tasks-ul > li ul li > span {
    width: 25%;
    border: none;
    border-left: 1px solid #444444;
  }
  .TasksListDetails ul.tasks-ul > li ul li span {
    border-radius: 0 !important;
    moz-border-radius: 0 !important;
    webkit-border-radius: 0 !important;
    padding: 0;
    height: 100%;
    line-height: 1.8125rem;
  }
  .TasksListDetails ul.tasks-ul > li ul li span:first-child {
    border-left: none;
  }
  .TasksListDetails ul.tasks-ul > li ul li span select {
    height: calc(1.8125rem - 0px);
    moz-border-radius: 0;
    webkit-border-radius: 0;
    border-radius: 0;
  }
  .TasksListDetails ul.tasks-ul > li ul li span.activity,
  .TasksListDetails ul.tasks-ul > li ul li span.admins {
    padding: 0;
  }
  .TasksListDetails ul.tasks-ul > li ul li .buttons {
    display: none;
  }
  .TasksListDetails .activity,
  .TasksListDetails .admins,
  .TasksListDetails .when,
  .TasksListDetails .status,
  .TasksListDetails .user {
    background-color: #FFFFFF !important;
  }
  .TasksListDetails .no-print {
    display: none;
  }
}
.dark-app .TasksListDetails ul.tasks-ul > li h3 {
  border-bottom: 1px solid #000000;
}
.dark-app .TasksListDetails ul.tasks-ul > li ul li > span {
  background: #2A2A2A;
}
.dark-app .TasksListDetails ul.tasks-ul > li ul li span.activity,
.dark-app .TasksListDetails ul.tasks-ul > li ul li span.admins {
  background: #1A1A1A;
}
.dark-app .TasksListDetails ul.tasks-ul > li ul li .status.change {
  background-color: #2A2A2A;
  border: none;
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.EditDashboard .editWidgetDiv {
  height: 100%;
  display: flex;
  height: 200px;
  width: 100%;
}
.EditDashboard .insertWidgetButtonLeft {
  display: flex;
  width: 10%;
  height: 200px;
  align-items: center;
  justify-content: left;
}
.EditDashboard .insertWidgetButtonLeft Button {
  height: 200px;
  width: 40px;
}
.EditDashboard .insertWidgetButtonRight {
  display: flex;
  width: 10%;
  height: 200px;
  align-items: center;
  justify-content: right;
}
.EditDashboard .insertWidgetButtonRight Button {
  height: 200px;
  width: 40px;
}
.EditDashboard .modifyWidgetButton {
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.EditDashboard .modifyWidgetButton Button {
  margin: 0 0 5px 0;
  width: 60%;
  font-size: 0.7vw;
}

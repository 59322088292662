/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.StatsCard {
  background-color: #757575;
  padding: 5px;
  padding-left: 15px;
}
.StatsCard :hover {
  background-color: #FF9900;
  color: #212529;
}
.StatsCard .linkStats {
  text-decoration: none;
  color: inherit;
}
.StatsCard .linkStats :hover {
  text-decoration: none;
}

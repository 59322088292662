/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.PricinglistsList .name-cell {
  text-decoration: none;
  font-weight: bold;
  font-size: 15px;
  color: #212529;
}

.dark-app .PricinglistsList .name-cell {
  color: #FFFFFF;
}

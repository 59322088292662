/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
@media (max-width: 1468px) {
  .StatsHourly .hours-col-3 {
    max-width: 33.3333333333% !important;
  }
  .StatsHourly .hours-col-4 {
    max-width: 25% !important;
  }
  .StatsHourly .hours-col-5 {
    max-width: 20% !important;
  }
  .StatsHourly .hours-col-6 {
    max-width: 16.6666666667% !important;
  }
  .StatsHourly .hours-col-7 {
    max-width: 14.2857142857% !important;
  }
  .StatsHourly .hours-col-8 {
    max-width: 12.5% !important;
  }
  .StatsHourly .hours-col-9 {
    max-width: 11.1111111111% !important;
  }
  .StatsHourly .hours-col-10 {
    max-width: 10% !important;
  }
  .StatsHourly .hours-col-11 {
    max-width: 9.0909090909% !important;
  }
  .StatsHourly .hours-col-12 {
    max-width: 8.3333333333% !important;
  }
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.Widget {
  margin-top: 0;
  margin-bottom: 0;
  overflow: hidden !important;
  position: relative;
  min-height: 200px;
  color: #212529;
}
.Widget.widgetCenter {
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: center;
}
.Widget.widgetClickable:hover {
  cursor: pointer;
  filter: brightness(140%);
}

.dark-app .Widget {
  color: #FFFFFF !important;
}

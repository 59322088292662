.SettingsImagesField .InlineImageField {
  margin-bottom: 20px;
}
.SettingsImagesField .add-button-wrapper {
  height: 150px;
  cursor: pointer;
}
.SettingsImagesField .add-button-wrapper .add-button {
  border-radius: 0.25rem 0.25rem 0 0;
  height: 100%;
  border: 1px solid #898989;
  display: flex;
  justify-content: center;
  align-items: center;
}
.SettingsImagesField .add-button-wrapper .add-button i {
  font-size: 96px;
  color: #FFFFFF;
}
.SettingsImagesField .add-button-wrapper:hover .add-button i {
  color: #FF9900;
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.divPlaceholder {
  text-align: center;
  padding-top: 200px;
  font-size: x-large;
}

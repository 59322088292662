/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.ClearableTextInput {
  position: relative;
}
.ClearableTextInput .clearbtn {
  position: absolute;
  width: 27px;
  height: 27px;
  background-color: white;
  color: gray;
  border: none;
  top: 1.5px;
  right: 3px;
}
.ClearableTextInput .clearbtn:hover {
  background-color: white !important;
  color: gray !important;
}
.ClearableTextInput .clearbtn:active {
  background-color: white !important;
  color: gray !important;
}

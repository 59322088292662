/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.IntegrationsForm .card-title {
  margin-top: 0.75rem;
}
.IntegrationsForm .card-title > img {
  width: 30px;
  height: 30px;
  object-fit: contain;
  padding: 1px;
  margin-right: 10px;
  background: #FFFFFF;
  display: inline;
  border-radius: 4px;
}
.IntegrationsForm .tab-choice {
  flex: calc(25% - 10px);
  background: #1A1A1A;
  padding: 20px;
  margin: 5px;
  border: 1px solid #151515;
  cursor: pointer;
}
.IntegrationsForm .tab-choice:hover {
  background: #151515;
  border-color: #FF9900;
}
.IntegrationsForm .tab-choice:hover .tab-choice-title {
  color: #FF9900;
}
.IntegrationsForm .tab-choice:hover .btn {
  color: #FF9900;
}
.IntegrationsForm .tab-choice .tab-choice-title {
  font-weight: bold;
  text-align: center;
}
.IntegrationsForm .tab-choice img {
  width: 30px;
  height: 30px;
  object-fit: contain;
  padding: 1px;
  margin-right: 10px;
  background: #FFFFFF;
  display: block;
  margin: 0 auto 10px;
  border-radius: 4px;
}

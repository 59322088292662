/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.StatsPrices .ReactTable .rt-td:has(.custom-table-footer) {
  background-color: #b6b6b6;
  color: #212529;
}
.StatsPrices .ReactTable .rt-thead.-filters {
  display: none;
}
.StatsPrices .ReactTable .rt-tfoot {
  background-color: #b6b6b6;
  color: #212529;
}
@media print {
  .StatsPrices .ReactTable .rt-td:has(.custom-table-footer) {
    background-color: inherit;
    color: inherit;
  }
  .StatsPrices .ReactTable .rt-tfoot {
    border-top: 1px solid #212529;
  }
}
@media (max-width: 1468px) {
  .StatsPrices .ReactTable .rt-td, .StatsPrices .ReactTable .rt-th {
    max-width: 20% !important;
  }
}

.dark-app .StatsPrices .rt-td:has(.custom-table-footer) {
  background-color: #757575;
  color: #f8f9fa;
}

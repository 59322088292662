/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.GraphBoxOffice .settingsFormInput {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: end;
}
.GraphBoxOffice .settingsFormLabel {
  height: 40px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: start;
}
.GraphBoxOffice .settingsFormLabel div:first-of-type {
  width: 70%;
}
.GraphBoxOffice .settingsLabelRight {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: end;
}
.GraphBoxOffice .settingsInput {
  min-width: 0;
  width: 250px;
}
.GraphBoxOffice .submitButtons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
}
.GraphBoxOffice .submitButtons Button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6vw;
  font-size: 0.75vw;
}
.GraphBoxOffice .noComputeWrapper {
  height: 200px;
}
.GraphBoxOffice .noComputeWrapper .noCompute {
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.GraphBoxOffice .noComputeWrapper .noCompute h1 {
  display: block;
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.Shortcut .settingsFormInput {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.Shortcut .settingsFormLabel {
  height: 40px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.Shortcut .settingsFormLabel div:first-of-type {
  width: 70%;
}
.Shortcut .settingsLabelRight {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.Shortcut .settingsInput {
  min-width: 0;
  width: 250px;
}
.Shortcut .submitButtons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.Shortcut .submitButtons Button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6vw;
  font-size: 0.75vw;
}
.Shortcut .shortcut {
  display: block;
  text-decoration: none !important;
  font-size: 2vw;
  overflow: hidden;
  text-align: center;
  position: relative;
  mix-blend-mode: difference;
}
.Shortcut .shortcut :link, .Shortcut .shortcut .shortcut:visited, .Shortcut .shortcut .shortcut:hover, .Shortcut .shortcut .shortcut:active {
  color: #212529 !important;
}
.Shortcut .shortcut p {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
}
.Shortcut .shortcut i {
  display: block;
  position: absolute;
  font-size: 12vw;
  color: #757575;
  top: 25px;
  left: 30px;
  z-index: 0;
  mix-blend-mode: difference;
  opacity: 0.6;
}
@media screen and (max-width: 767px) {
  .Shortcut .shortcut p {
    font-size: 40px !important;
  }
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.ThemeSettings {
  background: #1A1A1A;
  padding: 1px 20px 10px 20px;
  border-radius: 4px;
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.TickettypeForm .ui.multiple.search.dropdown {
  min-width: 240px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.TickettypeForm .SelectField > .selection {
  margin: 0 !important;
}
.TickettypeForm .btn_addFields {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.ArticleFeatures .feature.jumbotron {
  border: 1px solid #454545;
  padding-top: 0 !important;
  padding-bottom: 10px !important;
  border-radius: 4px;
  moz-border-radius: 4px;
  webkit-border-radius: 4px;
}
.ArticleFeatures .feature.jumbotron .feature-header {
  border-radius: 4px 4px 0 0;
  moz-border-radius: 4px 4px 0 0;
  webkit-border-radius: 4px 4px 0 0;
  background: #171616;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 0;
  cursor: pointer;
}
.ArticleFeatures .feature.jumbotron .feature-body {
  padding-top: 10px;
  margin-bottom: 0;
}
.ArticleFeatures .feature.jumbotron .feature-footer {
  text-align: center;
  border-top: 1px solid #454545;
  padding-top: 10px;
  margin-bottom: 0;
}
.ArticleFeatures .feature.jumbotron.active .feature-header {
  border-bottom: 1px solid #454545;
}

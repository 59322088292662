.InlineImageField .card .img-wrapper {
  border-radius: 0.25rem 0.25rem 0 0;
  height: 150px;
  padding: 5px;
  background-color: #151515;
}
.InlineImageField .card .img-wrapper .dropzone {
  background: url("../../assets/images/picture-bg.png") repeat;
  border-radius: 0.25rem 0.25rem 0 0;
  box-shadow: 0 0 3px 1px #0b0b0b;
  padding: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.InlineImageField .card .img-wrapper .dropzone img.card-img-top {
  object-fit: scale-down;
  max-height: 100%;
  transition: all 0.25s;
}
.InlineImageField .card .img-wrapper .dropzone p {
  text-align: center;
  opacity: 0;
  transition: all 0.25s;
  position: absolute;
}
.InlineImageField .card .img-wrapper .dropzone:hover {
  border: 1px dashed #888888;
}
.InlineImageField .card .img-wrapper .dropzone:hover img.card-img-top {
  opacity: 0;
}
.InlineImageField .card .img-wrapper .dropzone:hover p {
  opacity: 1;
}
.InlineImageField .card .card-footer {
  text-align: center;
}

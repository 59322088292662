/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
@media (max-width: 1468px) {
  .StatsRooms .rt-td, .StatsRooms .rt-th {
    max-width: 14.2857142857% !important;
  }
}

.CollectorTransactions .event {
  display: flex;
  background: rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #000000;
}
.CollectorTransactions .event:last-child {
  border-bottom: none;
}
.CollectorTransactions .event .left {
  padding: 8px;
  background: #000000;
}
.CollectorTransactions .event .right {
  padding: 8px;
}
.CollectorTransactions .event .right .text {
  font-size: 16px;
}
.CollectorTransactions .event .right .datetime {
  font-size: 12px;
}
.CollectorTransactions .event:hover {
  background: #000000;
}

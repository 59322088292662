/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.StatstypesList .btnStats {
  margin: 0 !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border: none !important;
}
.StatstypesList .btnActive {
  background-color: #FF9900 !important;
  color: #212529 !important;
}
.StatstypesList .btnNotActive {
  background-color: #212529 !important;
  color: #f8f9fa !important;
}
.StatstypesList .btnActive:disabled {
  background-color: #FF9900 !important;
  color: #212529 !important;
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.AvailabilitiesField .availability {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #C8C9CA;
  border-radius: 4px;
  width: fit-content;
  margin-bottom: 1rem;
}
.AvailabilitiesField .availability span {
  padding: 0.5rem 1rem 0.5rem 0.5rem;
}

.FormLayouts .SortableList {
  width: 100%;
  display: inline-flex;
  vertical-align: top;
  padding: 10px;
}
.FormLayouts .SortableList > ul {
  display: block;
  width: 100%;
  list-style-type: none;
  padding: 0;
  clear: both;
}
.FormLayouts .SortableList > ul > li {
  display: block;
  float: left;
  max-width: 100%;
  padding: 5px;
  position: relative;
}
.FormLayouts .SortableList > ul > li .drag-handle {
  position: absolute;
  right: 6px;
  color: #000000;
  z-index: 100;
  cursor: pointer;
}
.FormLayouts .SortableList > ul > li .rdtPicker {
  background: #000000;
}
.FormLayouts .SortableList > ul > li > div {
  width: 100%;
}
.FormLayouts .SortableList > ul > li > div .editable-content {
  border: 1px dashed #222222;
  width: 100%;
  cursor: pointer;
}
.FormLayouts .SortableList > ul > li > div.active .editable-content {
  border: 1px solid #FF9725;
  box-shadow: 0 0 10px 1px #FF9725;
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.Instance .TopBar {
  display: flex;
  justify-content: center;
  box-shadow: none !important;
  border-bottom: 1px solid #CCC;
}
@media (min-width: 767px) {
  .Instance .TopBar {
    max-width: 150px;
    top: 0;
    right: 0;
    left: auto;
    border-left: 1px solid #CCC;
  }
}
.Instance .TopBar .navbar-brand {
  box-shadow: none;
}
.Instance .TopBar .navbar-brand .logo {
  max-height: 100%;
  max-width: 100%;
}
.Instance .TopBar .langsNav {
  margin: 0 !important;
  width: 100%;
}
.Instance .TopBar .langsNav .dropdown-menu {
  border-radius: 0 !important;
}
@media (max-width: 767px) {
  .Instance .TopBar .langsNav .dropdown-menu {
    border-bottom: 0;
  }
}
.Instance .TopBar .langsNav a {
  color: #212529 !important;
}
.Instance .TopBar .langsNav button:active {
  color: #FFFFFF !important;
  background-color: #757575 !important;
}
.Instance .TopBar .langsNav button.active {
  background-color: #212529;
}
.Instance > .wrapper {
  padding: 20px 10px 10px;
}
.Instance > .wrapper .container > img {
  max-width: 100%;
}
@media (max-width: 767px) {
  .Instance > .wrapper {
    padding: 10px;
    margin-top: 40px;
  }
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
@media screen and (max-width: 767px) {
  .Dashboards .editViewBtn {
    display: none;
  }
}

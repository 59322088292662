/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.Draggable {
  height: auto !important;
}
.Draggable .cardBtnHeader {
  background-color: #FFFFFF;
  color: black;
}
.Draggable .drag-handle {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.Draggable .header-rules {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
.Draggable .hoverable:hover {
  background-color: #FF9900 !important;
  color: white;
  cursor: pointer !important;
}

.dark-app .Draggable .cardBtnHeader {
  background-color: #444444;
  color: white;
}
.dark-app .Draggable .hoverable:hover {
  background-color: #FF9900 !important;
  color: white;
}

body .sortablehelper {
  background-color: rgba(26, 26, 26, 0.25) !important;
}
body .sortablehelper > .cardBtnHeader {
  background-color: rgba(42, 42, 42, 0.25) !important;
}
body .sortablehelper .btn-danger {
  display: none !important;
}

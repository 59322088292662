/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.ApplicationsList .ReactTable .rt-td {
  display: flex;
  align-items: center;
  min-height: 60px;
}
.ApplicationsList .ReactTable .rt-td > div {
  flex: 1;
}
.ApplicationsList .ReactTable .rt-td .description_cell,
.ApplicationsList .ReactTable .rt-td .user_cell {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ApplicationsList .ReactTable .rt-td .description_cell .img_wrapper,
.ApplicationsList .ReactTable .rt-td .user_cell .img_wrapper {
  width: 64px;
  height: 64px;
  margin: 0 10px;
  text-align: center;
}
.ApplicationsList .ReactTable .rt-td .description_cell .img_wrapper img,
.ApplicationsList .ReactTable .rt-td .user_cell .img_wrapper img {
  max-width: 64px;
  max-height: 64px;
  border-radius: 4px;
}
.ApplicationsList .ReactTable .rt-td .description_cell a,
.ApplicationsList .ReactTable .rt-td .user_cell a {
  color: #FFFFFF !important;
  display: flex;
  flex-direction: column;
}
.ApplicationsList .ReactTable .rt-td .description_cell a .fullname,
.ApplicationsList .ReactTable .rt-td .user_cell a .fullname {
  font-weight: bold;
}
.ApplicationsList .ReactTable .rt-td .description_cell a .email,
.ApplicationsList .ReactTable .rt-td .user_cell a .email {
  font-style: italic;
  font-size: 90%;
}

.dark-app .ApplicationsList .ReactTable .rt-td .description_cell a,
.dark-app .ApplicationsList .ReactTable .rt-td .name_cell a {
  color: #FFFFFF !important;
}

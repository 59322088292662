/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.CustomerForm .row.main {
  margin-bottom: 20px;
}
.CustomerForm .row div.card {
  height: 100%;
}
.CustomerForm .row label.form-check-label {
  margin-left: 1.25rem;
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.SettingOverrideField .card-title {
  margin-top: 0.75rem;
}
.SettingOverrideField .ui.multiple.search.dropdown {
  min-width: 280px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.SettingOverrideField .add_btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

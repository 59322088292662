/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.Help #hkbembed-button {
  z-index: 100;
  --hkbembed-bg: #065461;
  --hkbembed-color: $base_color;
}
.Help #ht-kb-fe-embed-contents {
  display: none;
  z-index: 100;
}
.Help #ht-kb-fe-embed-contents iframe {
  position: absolute;
  right: 0;
  bottom: 0;
  display: block;
  opacity: 1 !important;
  visibility: visible !important;
}

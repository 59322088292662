.CollectorManagement > .row {
  margin-bottom: 0 !important;
}
.CollectorManagement .PageTitle {
  margin-bottom: 0;
}
.CollectorManagement .card {
  background-color: #08090a !important;
}
.CollectorManagement .top-badges {
  text-align: center;
}
.CollectorManagement .top-badges h4 {
  display: inline-block;
}
.CollectorManagement .top-badges h4 .badge {
  border-radius: 0;
  font-weight: normal;
}
.CollectorManagement .col-title {
  border-bottom: 1px solid #FFFFFF;
}
.CollectorManagement .operation {
  padding: 40px 20px;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none;
}
.CollectorManagement .operation .icon {
  text-align: right;
  max-width: 80px;
  position: absolute;
  top: 10px;
  right: 20px;
}
.CollectorManagement .operation .title {
  flex: 1;
  text-align: left;
}
.CollectorManagement .operation .title .subtitle {
  font-size: 18px;
}
.CollectorManagement .operation.form-visible {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.CollectorManagement .operation-form {
  background: #161616;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  overflow: hidden;
}
.CollectorManagement .operation-form .form-body {
  padding: 10px;
}
.CollectorManagement .operation-form .form-footer {
  padding: 10px;
  background: #484848;
  text-align: center;
}

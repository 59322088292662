/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.ScreensSettings .screen-setting {
  background: #1A1A1A;
  padding: 10px;
  margin: 0 0 5px 0;
  border: 1px solid #151515;
  border-radius: 4px;
}
.ScreensSettings .screen-setting:hover {
  background: #151515;
}
.ScreensSettings .screen-setting label {
  display: flex;
  align-items: center;
}
.ScreensSettings .ScreenWidgetsSettings {
  background: #1A1A1A;
  padding: 10px 20px;
  border-radius: 4px;
}

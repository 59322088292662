/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.SearchBloc .wrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
}
.SearchBloc .wrapper .title {
  padding: 5px;
  background: #F1F0F0;
  border: 1px solid #292929;
  border-bottom: none;
  text-align: center;
  font-weight: bold;
  border-radius: 4px 4px 0 0;
  moz-border-radius: 4px 4px 0 0;
  webkit-border-radius: 4px 4px 0 0;
}
.SearchBloc .wrapper .input {
  border-radius: 0;
  moz-border-radius: 0;
  webkit-border-radius: 0;
}
.SearchBloc .wrapper .input:last-child {
  border-radius: 0 0 4px 4px;
  moz-border-radius: 0 0 4px 4px;
  webkit-border-radius: 0 0 4px 4px;
}
.SearchBloc .wrapper .text-input {
  padding: 5px;
  border: 1px solid #292929;
  border-bottom: none;
  display: flex;
  align-items: baseline;
  border-bottom: 1px solid #292929;
  position: relative;
}
.SearchBloc .wrapper .text-input:hover {
  background: #292929;
}
.SearchBloc .wrapper .text-input .input-group .input-group-prepend .input-group-text {
  border-radius: 0;
  moz-border-radius: 0;
  webkit-border-radius: 0;
  min-width: 53px;
  text-align: center;
  display: inline;
}
.SearchBloc .wrapper .text-input .input-group input {
  height: 38px !important;
  border-radius: 0;
}
.SearchBloc .wrapper .text-input i {
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 5px;
  color: rgba(0, 0, 0, 0.87);
  padding: 12px 14px;
  font-size: 13px;
}
.SearchBloc .wrapper .tags-input {
  padding: 5px;
  border: 1px solid #292929;
  border-top: none;
  display: flex;
  align-items: baseline;
  border-bottom: 1px solid #292929;
  position: relative;
}
.SearchBloc .wrapper .tags-input:hover {
  background: #292929;
}
.SearchBloc .wrapper .tags-input .input-group .input-group-prepend .input-group-text {
  border-radius: 0;
  moz-border-radius: 0;
  webkit-border-radius: 0;
  width: 53px;
  text-align: center;
  display: inline;
}
.SearchBloc .wrapper .tags-input .input-group .ui.dropdown {
  flex: 1 1;
  border-radius: 0;
  moz-border-radius: 0;
  webkit-border-radius: 0;
  width: calc(100% - 54px) !important;
  min-width: 0 !important;
}
.SearchBloc .wrapper .tags-input .input-group input {
  height: 30px;
  margin: 0 !important;
  border-radius: 0;
}

.dark-app .SearchBloc .wrapper .title {
  background: #151515;
}
.dark-app .SearchBloc .wrapper .text-input {
  background: #171616;
}
.dark-app .SearchBloc .wrapper .text-input:hover {
  background: #292929;
}
.dark-app .SearchBloc .wrapper .text-input i {
  color: rgba(0, 0, 0, 0.87);
}
.dark-app .SearchBloc .wrapper .tags-input {
  background: #171616;
}
.dark-app .SearchBloc .wrapper .tags-input:hover {
  background: #292929;
}

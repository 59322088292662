/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
/** Ouside of component wrap because modals are attached to body */
.modalCustomClass {
  min-width: auto !important;
}
.modalCustomClass .prepend-addon {
  width: 36px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}
.modalCustomClass .prepend-addon i {
  width: 14px;
}

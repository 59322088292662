/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.ResponsiveNav {
  display: flex;
  position: relative;
}
.ResponsiveNav .pad {
  width: 15px;
  height: 36px;
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 1000;
}
.ResponsiveNav .pad-left {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.5));
  left: 0;
}
.ResponsiveNav .pad-right {
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.5));
  right: 0;
}
.ResponsiveNav .nav-box {
  display: flex;
  width: 100% !important;
  overflow-x: scroll !important;
  overflow-y: hidden !important;
  scrollbar-width: none;
}
.ResponsiveNav .nav-box .nav {
  border-bottom: none !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 36px;
}
.ResponsiveNav .nav-box ::-webkit-scrollbar {
  width: 0.1em;
}
.ResponsiveNav .nav-box ::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.dark-app .ResponsiveNav .nav-box {
  border-bottom: 1px solid #C8C9CA;
}
.dark-app .ResponsiveNav .pad-left {
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgb(0, 0, 0));
  left: 0;
}
.dark-app .ResponsiveNav .pad-right {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgb(0, 0, 0));
  right: 0;
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.ColorPickerField .btn {
  width: 100px;
}
.ColorPickerField .btn .colorValue {
  background: #FFFFFF;
  color: #000000;
  width: 100%;
  display: inline-block;
  border-radius: 2px;
  font-size: 12px;
  border: 1px solid #AAAAAA;
}
.ColorPickerField .popover {
  position: absolute;
  zIndex: 2;
}
.ColorPickerField .cover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

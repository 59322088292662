.CollectorSituationResume .gauges {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: auto;
}
.CollectorSituationResume .gauges .gauge {
  max-width: 90px;
  flex: 0.09;
  background: #28A745;
  margin: 0;
  padding: 2px;
  font-size: 14px;
}
.CollectorSituationResume .gauges .gauge.gauges-title {
  max-width: inherit;
  min-width: 80px;
  background: #080808;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ACACAC;
}
.CollectorSituationResume .gauges .gauge.gauges-title div {
  font-weight: bold;
  margin-bottom: 0;
}
.CollectorSituationResume .gauges .gauge.gauges-title small {
  font-style: italic;
}
.CollectorSituationResume .gauges .gauge .gauge-inner {
  padding: 2px;
  background: rgb(0, 0, 0);
}
.CollectorSituationResume .gauges .gauge .gauge-inner .gauge-title {
  text-align: center;
  font-weight: bold;
}
.CollectorSituationResume .gauges .gauge .gauge-inner .values {
  text-align: center;
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.StatsMovies .colAst {
  text-align: center;
}
.StatsMovies #printContainer {
  width: 100%;
  margin: 10px;
  padding-left: 20px;
  padding-right: 20px;
}
.StatsMovies .printTable {
  margin-bottom: 10px;
  margin-top: 20px;
}
.StatsMovies .income {
  display: flex !important;
  text-align: end !important;
  align-items: end !important;
  justify-content: end !important;
}
.StatsMovies .singleMovieIncome {
  border-top: solid 1px #212529;
}
.StatsMovies .subTable {
  border-left: none;
  border-right: none;
}
.StatsMovies .subTable .rt-tr {
  background-color: #FFFFFF !important;
}
.StatsMovies .subTable .rt-tr:hover {
  filter: brightness(0.9);
}
@media (max-width: 767px) {
  .StatsMovies .subTable {
    font-size: 0.8em !important;
  }
}
@media (max-width: 1468px) {
  .StatsMovies .printStatsMovies .rt-td,
  .StatsMovies .printStatsMovies .rt-th {
    max-width: 10% !important;
  }
  .StatsMovies .printStatsMovies .expander {
    display: none !important;
  }
}
@media print {
  .StatsMovies .rt-td:last-child {
    text-align: right;
  }
  .StatsMovies .printStatsMovies .rt-td,
  .StatsMovies .printStatsMovies .rt-th:nth-child(-n+3) {
    max-width: 16.6666666667% !important;
  }
  .StatsMovies .printStatsMovies .rt-td,
  .StatsMovies .printStatsMovies .rt-th:last-child(-n+7) {
    max-width: 8.3333333333%;
  }
  .StatsMovies .printStatsMovies .total-entries-col {
    display: none !important;
  }
  .StatsMovies .printStatsMovies .expander {
    display: none !important;
  }
  .StatsMovies .subTable {
    font-weight: normal;
  }
  .StatsMovies .total-entries-col {
    display: block;
  }
  .StatsMovies .tr-td {
    background-color: white;
  }
}

.dark-app .StatsMovies .singleMovieIncome {
  border-top: solid 1px #444444;
}
.dark-app .StatsMovies .-even .rt-td {
  background-color: #171616;
}
.dark-app .StatsMovies .-even:hover {
  background-color: #242222;
}
.dark-app .StatsMovies .subTable .rt-tr .rt-td {
  background-color: #757575;
}
.dark-app .StatsMovies .subTable .rt-tr:hover {
  filter: brightness(0.9);
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.ArticlecategoriesList .top-buttons-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}
.ArticlecategoriesList .sortable-grid {
  background: #f8f9fa;
  margin: 1rem 0;
  padding-bottom: 1rem;
}
.ArticlecategoriesList .sortable-grid .drag-handle {
  position: absolute;
  z-index: 100;
  top: 5px;
  left: 5px;
  color: #212529;
  font-size: 16px;
  width: 60%;
  cursor: pointer;
  width: 60%;
}
.ArticlecategoriesList .articlecategories-list .message {
  border: 1px solid #292929;
  padding: 6px 10px;
  background: #151515;
  font-size: 14px;
  color: #FFFFFF;
  text-decoration: none;
  width: 300px;
  max-width: 100%;
  text-align: center;
  margin: 0 auto;
  display: block;
}
.ArticlecategoriesList .articlecategories-list .message i {
  margin-right: 5px;
}
.ArticlecategoriesList .ReactTable .rt-td {
  min-height: 60px;
}
.ArticlecategoriesList .ReactTable .rt-td .articlecategory_cell .card-body {
  padding: 0;
}
.ArticlecategoriesList .ReactTable .rt-td .articlecategory_cell .card-body .title-wrapper {
  background: rgba(0, 0, 0, 0.5);
  height: 52px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
}
.ArticlecategoriesList .ReactTable .rt-td .articlecategory_cell .card-body .title-wrapper .card-title {
  width: 94%;
  white-space: normal;
  margin-bottom: 0;
  border-bottom: none;
  font-size: 16px;
  font-weight: bold;
}
.ArticlecategoriesList .ReactTable .rt-td .articlecategory_cell .card-body .title-wrapper .card-title small {
  display: block;
}
.ArticlecategoriesList .card.articlecategory-card {
  width: 100%;
  max-width: 130px;
  height: 100%;
  max-height: 130px;
}
.ArticlecategoriesList .card.articlecategory-card .card-body {
  position: relative;
  padding: 0;
  height: 100%;
}
.ArticlecategoriesList .card.articlecategory-card .card-body .articlecategory {
  color: #212529;
  width: 100%;
}
.ArticlecategoriesList .card.articlecategory-card .card-body .deleteCategoryBtn {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1000 !important;
}
.ArticlecategoriesList .card.articlecategory-card .card-body .deleteCategoryBtn i {
  font-size: 20px;
  color: #6f0913;
}
.ArticlecategoriesList .card.articlecategory-card .card-body .title-wrapper {
  background: #F1F0F0;
  min-height: 80px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
}
.ArticlecategoriesList .card.articlecategory-card .card-body .title-wrapper .card-title {
  width: calc(100% - 25px);
  padding-top: 25px !important;
  white-space: normal !important;
  margin: 0 !important;
  border: none !important;
  font-size: 14px !important;
  font-weight: 700;
  display: block;
  width: calc(100% - 10px);
  margin: 0 auto;
  overflow: hidden;
  text-align: center;
}
.ArticlecategoriesList .card.articlecategory-card .card-body .title-wrapper .card-title small {
  display: block;
}
@media (max-width: 767px) {
  .ArticlecategoriesList .articlecategory-card {
    max-width: none !important;
  }
}

.dark-app .sortable-grid {
  background: #151515;
}
.dark-app .sortable-grid .drag-handle {
  color: #FFFFFF;
}
.dark-app .articlecategories-list .message {
  background: #151515;
  color: #FFFFFF;
}
.dark-app .articlecategories-list .card.articlecategory-card .card-body .title-wrapper {
  background: #212529;
}

body > .col-sm-2 .card.articlecategory-card {
  background-color: rgba(0, 0, 0, 0);
  width: 130px !important;
  height: 80px !important;
}
body > .col-sm-2 .card.articlecategory-card .drag-handle {
  color: #FFFFFF;
}
body > .col-sm-2 .card.articlecategory-card .btn {
  display: none;
}
body > .col-sm-2 .card.articlecategory-card .title-wrapper {
  display: none;
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.PhoneField {
  height: 33.5px;
}
.PhoneField .indicative {
  width: 100px;
  display: inline-block;
  height: 100% !important;
  border-radius: 0.25rem 0 0 0.25rem;
}
.PhoneField .number {
  width: calc(100% - 100px);
  display: inline-block;
  height: 100% !important;
  border-radius: 0 0.25rem 0.25rem 0;
}

.CollectorStats .gauges {
  display: flex;
  flex-wrap: wrap;
}
.CollectorStats .gauges .gauge {
  flex: 0.2;
  background: rgba(0, 0, 0, 0.5);
  margin: 0.1rem;
  padding: 0.8rem;
}
.CollectorStats .gauges .gauge .gauge-title {
  text-align: center;
}
.CollectorStats .gauges .gauge .gauge-title .values {
  font-size: 80%;
}

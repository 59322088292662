/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.UserForm .row.main {
  margin-bottom: 20px;
}
.UserForm .row div.card {
  height: 100%;
}
.UserForm .row label.form-check-label {
  margin-left: 1.25rem;
}
.UserForm .test {
  width: 50% !important;
}

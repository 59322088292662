.CollectorSituation {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.CollectorSituation .gauges {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: auto;
}
.CollectorSituation .gauges .gauge {
  max-width: 90px;
  flex: 0.09;
  background: #28A745;
  margin: 0;
  padding: 2px;
  font-size: 14px;
}
.CollectorSituation .gauges .gauge.gauges-title {
  max-width: inherit;
  min-width: 200px;
  background: #080808;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ACACAC;
}
.CollectorSituation .gauges .gauge.gauges-title h3 {
  font-weight: bold;
  margin-bottom: 0;
}
.CollectorSituation .gauges .gauge.gauges-title small {
  font-style: italic;
}
.CollectorSituation .gauges .gauge.separator {
  max-width: 20px;
  background: transparent;
  border: none;
}
.CollectorSituation .gauges .gauge.warning {
  background: #FF9400;
  border-color: #cc7600;
}
.CollectorSituation .gauges .gauge.warning:hover {
  background: #995900;
  border-color: #331e00;
}
.CollectorSituation .gauges .gauge.danger {
  background: #BB1919;
  border-color: #8e1313;
}
.CollectorSituation .gauges .gauge.danger:hover {
  background: #610d0d;
  border-color: #070101;
}
.CollectorSituation .gauges .gauge:hover {
  background: #145523;
  border-color: #010201;
}
.CollectorSituation .gauges .gauge:first-child {
  border-radius: 0 0 0 12px;
  moz-border-radius: 0 0 0 4px;
  webkit-border-radius: 0 0 0 4px;
  border-right: none;
  border-top: none;
}
.CollectorSituation .gauges .gauge:first-child .gauge-inner {
  border-radius: 0 0 0 12px;
  moz-border-radius: 0 0 0 4px;
  webkit-border-radius: 0 0 0 4px;
}
.CollectorSituation .gauges .gauge:last-child {
  border-radius: 0 0 12px 0;
  moz-border-radius: 0 0 4px 0;
  webkit-border-radius: 0 0 4px 0;
  border-left: none;
  border-top: none;
}
.CollectorSituation .gauges .gauge:last-child .gauge-inner {
  border-radius: 0 0 12px 0;
  moz-border-radius: 0 0 4px 0;
  webkit-border-radius: 0 0 4px 0;
}
.CollectorSituation .gauges .gauge .gauge-inner {
  padding: 2px;
  background: rgb(0, 0, 0);
}
.CollectorSituation .gauges .gauge .gauge-inner .gauge-title {
  text-align: center;
}
.CollectorSituation .gauges .gauge .gauge-inner .gauge-title .values {
  font-size: 80%;
}
.CollectorSituation .gauges .gauge .gauge-inner .gauge-title svg {
  display: none;
  vertical-align: top;
}
.CollectorSituation .gauges .gauge .gauge-inner .gauge-chart {
  max-width: 86px;
  margin: 0 auto;
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.TranslatableField .translatable-field-wrapper > div > div > div > div {
  z-index: 1 !important;
}
.TranslatableField .lang-switchers {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  margin-right: 0.5rem;
  border-right: 1px solid #C8C9CA;
}
.TranslatableField .lang-switchers .lang-switcher {
  background-color: #2A2A2A;
  color: #FFFFFF;
  border-top: 1px solid #C8C9CA;
  border-left: 1px solid #C8C9CA;
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  cursor: pointer;
  padding: 0.2rem 1rem;
}
.TranslatableField .lang-switchers .lang-switcher.active {
  background-color: #FF9900;
  color: #212529;
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.MultiSelectField .ui.label {
  font-weight: 400;
  color: #212529;
}
.MultiSelectField .rolesTooltips::after {
  width: 50%;
  white-space: pre-wrap;
}

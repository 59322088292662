/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.PageTitle {
  color: #414141;
  border-bottom: 2px solid #414141;
  padding-bottom: 5px;
  margin-bottom: 20px;
}

.dark-app .PageTitle {
  border-bottom: 2px solid #FFFFFF;
  color: #FFFFFF;
}

@media print {
  .PageTitle .float-right {
    display: none;
  }
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.Login .wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
}
.Login .wrapper .background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #FFFFFF;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  z-index: 1;
  animation: blur 3s linear 0s 1 alternate;
  moz-animation: blur 3s linear 0s 1 alternate;
  webkit-animation: blur 3s linear 0s 1 alternate;
  filter: blur(4px);
  webkit-filter: blur(4px);
  moz-filter: blur(4px);
  opacity: 0.75;
}
@keyframes blur {
  from {
    filter: blur(0px);
    opacity: 1;
  }
  to {
    filter: blur(4px);
    opacity: 0.75;
  }
}
@-webkit-keyframes blur {
  from {
    -webkit-filter: blur(0px);
    opacity: 1;
  }
  to {
    -webkit-filter: blur(4px);
    opacity: 0.75;
  }
}
@-moz-keyframes blur {
  from {
    -moz-filter: blur(0px);
    opacity: 1;
  }
  to {
    -moz-filter: blur(4px);
    opacity: 0.75;
  }
}
.Login .wrapper .form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
  border: 1px solid #FFFFFF;
  border-radius: 4px;
  moz-border-radius: 4px;
  webkit-border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.65);
  z-index: 2;
  animation: appear 3s linear 0s 1 alternate;
  moz-animation: appear 3s linear 0s 1 alternate;
  webkit-animation: appear 3s linear 0s 1 alternate;
}
.Login .wrapper .form-signin .logo {
  margin-bottom: 20px;
}
.Login .wrapper .form-signin .checkbox {
  font-weight: 400;
}
.Login .wrapper .form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.Login .wrapper .form-signin .form-control:focus {
  z-index: 2;
}
.Login .wrapper .form-signin input[type=email] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.Login .wrapper .form-signin input[type=password] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.Login .wrapper .form-signin .error-msg {
  color: white;
}
@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

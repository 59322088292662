@charset "UTF-8";
/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.App {
  background-color: #f8f9fa;
  color: #414141;
  height: 100%;
  font-family: Inter Tight, sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
.App .loader-container {
  position: fixed;
  top: 40%;
}
.App.light-app > .container-fluid {
  background-color: #f8f9fa;
  color: #414141;
}
.App.dark-app > .container-fluid {
  background-color: #171616;
  color: #FFFFFF;
}
.App .sortable-grid {
  background-color: transparent;
}
.App .container-fluid {
  background-color: #f8f9fa;
  color: #414141;
  margin-bottom: -20px !important;
}
.App .wrapper-0 {
  border-radius: 4px;
  padding: 10px;
  padding-left: 10px;
  background: #F1F0F0;
}
.App .wrapper-1 {
  border-radius: 4px;
  padding: 10px;
  padding-left: 15px;
  background: #F1F0F0;
}
.App .wrapper-2 {
  border-radius: 4px;
  padding: 10px;
  padding-left: 20px;
  background: #F1F0F0;
}
.App .wrapper-3 {
  border-radius: 4px;
  padding: 10px;
  padding-left: 25px;
  background: #F1F0F0;
}
.App .wrapper-4 {
  border-radius: 4px;
  padding: 10px;
  padding-left: 30px;
  background: #F1F0F0;
}
.App .text-v-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.App .no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.App .card {
  height: 100%;
  background-color: #F1F0F0;
  border-color: #b6b6b6;
}
.App .card .card-body .card-title {
  border-bottom: 1px solid #414141;
  padding-bottom: 0.5rem;
  font-size: 22px;
}
.App .card .card-body .card-title.small {
  font-size: 18px;
}
.App .card .card-body .card-text {
  color: #414141;
  background: #F1F0F0;
}
.App .row {
  margin-bottom: 10px;
}
.App .form .row.main {
  margin-bottom: 20px;
}
.App .nav.nav-tabs .nav-item {
  margin-right: 0.25rem;
}
.App .nav.nav-tabs .expandedMainContent,
.App .nav.nav-tabs .reducedMainContent {
  padding-left: 0 !important;
  width: 100% !important;
}
.App .ReactTable .align-center-stats {
  text-align: center;
}
.App .ReactTable .rt-table:not(.subTable) .rt-thead.-header .rt-tr .rt-th {
  border-top: solid 1px #444444 !important;
  border-bottom: solid 1px #444444 !important;
  border-left: solid 1px #444444 !important;
}
.App .ReactTable .rt-table:not(.subTable) .rt-thead.-header .rt-tr .rt-th:last-of-type {
  border-right: solid 1px #444444 !important;
}
.App .ReactTable .rt-table:not(.subTable) .rt-thead.-header .rt-tr .rt-th.-sort-desc .rt-resizable-header-content::after {
  content: " ▼";
}
.App .ReactTable .rt-table:not(.subTable) .rt-thead.-header .rt-tr .rt-th.-sort-asc .rt-resizable-header-content::after {
  content: " ▲";
}
.App .ReactTable .rt-table:not(.subTable) .rt-thead.-filters .rt-tr .rt-th {
  border-bottom: solid 1px #444444;
  border-left: solid 1px #444444;
}
.App .ReactTable .rt-table:not(.subTable) .rt-thead.-filters .rt-tr .rt-th:last-of-type {
  border-right: solid 1px #444444;
}
.App .ReactTable .rt-table:not(.subTable) .rt-tbody .rt-tr {
  border-bottom: solid 1px #444444;
}
.App .ReactTable .rt-table:not(.subTable) .rt-tbody .rt-tr .rt-td {
  border-left: solid 1px #444444;
}
.App .ReactTable .rt-table:not(.subTable) .rt-tbody .rt-tr .rt-td:last-of-type {
  border-right: solid 1px #444444;
  display: flex;
  align-items: center;
  justify-content: center;
}
.App .ReactTable .rt-table:not(.subTable) .rt-tbody .rt-tr.-even {
  background-color: #f8f9fa;
}
.App .ReactTable .rt-table:not(.subTable) .rt-tbody .rt-tr.-even:hover {
  background-color: #f2f4f6;
}
.App .ReactTable .rt-table:not(.subTable) .rt-tbody .rt-tr.-odd {
  background-color: #f5f6f8;
}
.App .ReactTable .rt-table:not(.subTable) .rt-tbody .rt-tr.-odd:hover {
  background-color: #f2f4f6;
}
.App .ReactTable .rt-table:not(.subTable) .rt-tfoot .rt-tr {
  border-bottom: solid 1px #444444;
}
.App .ReactTable .rt-table:not(.subTable) .rt-tfoot .rt-tr .rt-td {
  border-left: solid 1px #444444;
}
.App .ReactTable .rt-table:not(.subTable) .rt-tfoot .rt-tr .rt-td:last-of-type {
  border-right: solid 1px #444444;
}
@media print {
  .App .ReactTable .rt-resizer {
    display: none !important;
  }
  .App .ReactTable .pagination-bottom {
    display: none;
  }
  .App .ReactTable .-filters {
    display: none;
  }
}
.App .onlyPrint {
  display: none;
}
@media print {
  .App {
    background-color: white !important;
  }
  .App * {
    color: #212529;
    background-color: white !important;
  }
  .App .navToggle {
    display: none !important;
  }
  .App .onlyPrint {
    display: block;
  }
  .App .noPrint {
    display: none !important;
  }
  .App .expandedMainContent,
  .App .reducedMainContent {
    padding-left: 0 !important;
    width: 100% !important;
  }
  .App .ReactTable .rt-thead.-filters {
    display: none;
  }
  .App.dark-app {
    background-color: #171616;
    color: #FFFFFF;
  }
}
.App.dark-app {
  background-color: #171616;
}
.App.dark-app .-btn {
  color: #C8C9CA;
  background-color: #065461;
}
.App.dark-app .-btn:hover {
  background-color: #076979 !important;
}
.App.dark-app .-btn[disabled] {
  background-color: #212529;
}
.App.dark-app .-btn[disabled]:hover {
  background-color: #212529 !important;
}
.App.dark-app .ReactTable {
  color: white;
}
.App.dark-app .ReactTable .rt-tr.-even {
  background-color: #171616 !important;
}
.App.dark-app .ReactTable .rt-tr.-even:hover {
  background-color: #1c1b1b !important;
}
.App.dark-app .ReactTable .rt-tr.-odd {
  background-color: #1a1818 !important;
}
.App.dark-app .ReactTable .rt-tr.-odd:hover {
  background-color: #1c1b1b !important;
}
.App.dark-app .wrapper-0 {
  border-radius: 4px;
  padding: 10px;
  padding-left: 10px;
  background: #383838;
}
.App.dark-app .wrapper-1 {
  border-radius: 4px;
  padding: 10px;
  padding-left: 15px;
  background: #313131;
}
.App.dark-app .wrapper-2 {
  border-radius: 4px;
  padding: 10px;
  padding-left: 20px;
  background: #292929;
}
.App.dark-app .wrapper-3 {
  border-radius: 4px;
  padding: 10px;
  padding-left: 25px;
  background: #212121;
}
.App.dark-app .wrapper-4 {
  border-radius: 4px;
  padding: 10px;
  padding-left: 30px;
  background: #1a1a1a;
}
.App.dark-app .container-fluid {
  background-color: #171616;
  color: #C8C9CA;
}
.App.dark-app .card {
  border: 1px solid rgba(0, 0, 0, 0.3);
  background-color: #2A2A2A;
}
.App.dark-app .card .card-body .card-title {
  color: #FFFFFF;
  border-bottom: 1px solid #C8C9CA;
}
.App.dark-app .card .card-body .card-text {
  color: #FFFFFF;
  background-color: inherit;
}
.App.dark-app .card .card-body .card-footer {
  color: #FFFFFF;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
}
.App.dark-app .DraftEditor-root {
  color: #212529;
}
@media print {
  .App.dark-app * {
    background-color: white !important;
  }
}
.App.dark-app .nav.nav-tabs .nav-item a.nav-link {
  color: #FFFFFF;
}
.App.dark-app .nav.nav-tabs .nav-item a.nav-link.active {
  color: #212529;
  background-color: #FF9900;
  border-color: #FF9900 #FF9900 #FFFFFF;
}
.App.dark-app .nav.nav-tabs .nav-item a.nav-link.active:hover {
  color: #212529;
}
.App.dark-app .nav.nav-tabs .nav-item a.nav-link:hover {
  color: #FFFFFF;
}

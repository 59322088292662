/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.EmailField .email {
  width: 100%;
  display: inline-block;
  border-radius: 0.25rem;
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.PricingsForm .card.pricingCard {
  height: auto;
}

.sortableHelper {
  color: #FFFFFF;
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.ShowWidgetProps .widgetSettings {
  border: 1px solid;
  padding: 10px 10px 0 10px;
}
.ShowWidgetProps .widgetSettings h3 {
  border-bottom: 0.5px solid;
  padding-bottom: 5px;
}
.ShowWidgetProps .widgetSettings i:first-child {
  float: right;
}

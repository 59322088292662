/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.FlashInfo .alert {
  position: static !important;
}
.FlashInfo .alert .subtitle {
  font-weight: bold;
}
.FlashInfo .alert .content {
  font-style: italic;
}

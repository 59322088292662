/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.PaddingInput {
  width: 100%;
  display: flex;
  justify-content: center;
}
.PaddingInput .inputs input {
  max-width: 80px;
  background: #484848;
  color: #FFFFFF;
  border: 1px solid #151515;
  text-align: center;
  font-weight: bold;
}
.PaddingInput .inputs input.top {
  margin-left: 80px;
}
.PaddingInput .inputs input.left {
  float: left;
  margin-right: 80px;
}
.PaddingInput .inputs input.bottom {
  margin-left: 80px;
}

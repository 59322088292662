.MoneyValue {
  color: #080808;
  padding: 2px;
  background: #E6E6E6;
  border-radius: 4px;
  moz-border-radius: 4px;
  webkit-border-radius: 4px;
}
.MoneyValue input {
  text-align: center;
}
.MoneyValue .title {
  background: #E6E6E6;
  text-align: center;
  padding: 5px;
  border-radius: 4px 4px 0 0;
}

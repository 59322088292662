/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.FillBar :hover {
  cursor: pointer;
}
.FillBar .bar {
  font-size: 0.9em;
  color: white;
  min-height: 15px;
  height: 22px;
  width: 100%;
  background-color: transparent;
  display: flex;
  overflow: hidden;
}
.FillBar .bar .filler:first-of-type {
  text-align: left;
  justify-content: flex-start;
}
.FillBar .bar .filler:last-of-type {
  text-align: right;
  justify-content: flex-end;
  padding-right: 5px;
}
.FillBar .bar .filler {
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #D8D8D8;
  border-top-width: 0;
  border-left-width: 0;
}
.FillBar .bar .filler:first-of-type {
  border-top-width: 1;
}
.FillBar .bar .filler span {
  display: block;
  padding-left: 3px;
}
.FillBar:first-of-type .pre-label:first-of-type {
  border-top-left-radius: 4px;
  border-top-width: 1px;
}
.FillBar:first-of-type .filler:last-of-type {
  border-top-right-radius: 4px;
}
.FillBar:last-of-type .pre-label:last-of-type {
  border-bottom-left-radius: 4px;
}
.FillBar:last-of-type .filler:last-of-type {
  border-bottom-right-radius: 4px;
}
.FillBar .text {
  font-size: 0.9em;
  color: white;
  height: 15px;
  width: 100%;
  background-color: transparent;
  border-radius: 2px;
  display: flex;
}
.FillBar .pre-label {
  color: #121212;
  margin: 0;
  align-items: center;
  background: #FFFFFF;
  padding-left: 5px;
  border: 1px solid #D8D8D8;
  border-top-width: 0;
  border-right-width: 0;
  overflow: hidden;
}
.FillBar .text > .filler {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.dark-app .FillBar .filler {
  border-color: #454545;
}
.dark-app .FillBar .pre-label {
  color: #FFFFFF;
  background: #222222;
  border-color: #454545;
}

.SettingsMobileField .InlineImageField {
  margin-bottom: 20px;
}
.SettingsMobileField .add-button-wrapper {
  height: 150px;
  cursor: pointer;
}
.SettingsMobileField .add-button-wrapper .add-button {
  border-radius: 0.25rem 0.25rem 0 0;
  height: 100%;
  border: 1px solid #898989;
  display: flex;
  justify-content: center;
  align-items: center;
}
.SettingsMobileField .add-button-wrapper .add-button i {
  font-size: 96px;
  color: #FFFFFF;
}
.SettingsMobileField .add-button-wrapper:hover .add-button i {
  color: #FF9900;
}

.modal .step {
  border: 1px solid #F0F0F0;
  background: #FAFAFA;
  border-radius: 8px;
  moz-border-radius: 8px;
  webkit-border-radius: 8px;
  text-align: center;
  height: 100%;
  padding: 10px;
}
.modal .step .big-number {
  font-weight: bold;
  font-size: 250%;
  border: 1px solid #F0F0F0;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin: 0 auto 10px auto;
  line-height: 60px;
  background: #FFFFFF;
}
.modal .step:hover {
  border: 1px solid #F0F0F0;
  background: #F0F0F0;
}

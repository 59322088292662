/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.EventTitle {
  padding: 5px;
  background: rgba(0, 0, 0, 0.5);
  height: 52px;
  color: #FFFFFF;
  font-weight: bold;
}

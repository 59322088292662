/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.Weather .weatherDisplay {
  text-align: right !important;
}
.Weather .weatherDisplay .temperature {
  font-size: 2.6vw;
  line-height: 2vw;
}
.Weather .weatherDisplay .location {
  font-style: italic;
  font-size: 1.3vw;
}
.Weather .tempIcons {
  text-shadow: -1px 0 #757575, 0 1px #757575, 1px 0 #757575, 0 -1px #757575;
}
.Weather .settingsFormInput {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.Weather .settingsFormLabel {
  height: 40px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.Weather .settingsFormLabel div:first-of-type {
  width: 70%;
}
.Weather .settingsLabelRight {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.Weather .settingsInput {
  min-width: 0;
  width: 100%;
}
.Weather .submitButtons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.Weather .submitButtons Button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6vw;
  font-size: 0.75vw;
}
@media screen and (max-width: 767px) {
  .Weather .weatherBox {
    min-width: 60%;
  }
  .Weather .weatherDisplay .location {
    font-size: 3vw;
    line-height: 2vw;
  }
  .Weather .weatherDisplay .temperature {
    font-size: 5vw;
  }
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.ScreeningForm {
  max-width: 99.5%;
  table-layout: fixed;
}
.ScreeningForm .tableQuotas {
  width: 100% !important;
}
.ScreeningForm th,
.ScreeningForm td {
  border: solid 0.5px white;
  padding: 10px;
}
.ScreeningForm .bookingsCol {
  width: 400px !important;
}
.ScreeningForm .capacityCol {
  width: 295px !important;
}
.ScreeningForm .align-center-table {
  text-align: center;
}
@media screen and (max-width: 1000px) {
  .ScreeningForm .tableQuotas {
    width: 1000px;
    overflow-x: scroll;
  }
}
.ScreeningForm .ScreeningFormMap .map-select {
  padding-left: 5px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  border-bottom: solid black 0.5px;
  border-left: solid black 0.5px;
  border-right: solid black 0.5px;
  cursor: pointer !important;
}
.ScreeningForm .ScreeningFormMap .map-select.selected {
  background-color: #f8af42 !important;
}
.ScreeningForm .ScreeningFormMap .map-select:first-of-type {
  border-top: solid black 0.5px;
}
.ScreeningForm .link-pass {
  color: black;
}

.dark-app .ScreeningForm .link-pass {
  color: white;
}

.text-small.badge {
  font-size: x-small;
  padding: 5px 10px 3px 10px;
}

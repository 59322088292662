/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.TickettypesList .sortable-grid {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.TickettypesList .sortableTab {
  background-color: #2A2A2A;
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
@media (max-width: 1468px) {
  .StatsBoxOffice .rt-td, .StatsBoxOffice .rt-th {
    max-width: 12.5% !important;
  }
}

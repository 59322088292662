/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.ProgrammationsList #dateInput {
  width: 100%;
}
.ProgrammationsList .ReactTable .-filters .rt-th {
  overflow: inherit;
}
.ProgrammationsList .ReactTable .rt-td {
  min-height: 120px;
}
.ProgrammationsList .ReactTable .rt-td .events_cell .card-body {
  padding: 0;
}
.ProgrammationsList .ReactTable .rt-td .events_cell .card-body .title-wrapper {
  background: rgba(0, 0, 0, 0.5);
  height: 104px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
}
.ProgrammationsList .ReactTable .rt-td .events_cell .card-body .title-wrapper .card-title {
  width: 94%;
  white-space: normal;
  margin-bottom: 0;
  border-bottom: none;
  font-size: 16px;
  font-weight: bold;
}
.ProgrammationsList .ReactTable .rt-td .day {
  float: left;
  min-width: 40px;
  margin: 0 3px 3px 0;
  padding: 5px 10px;
  border: 1px solid #212529;
  background-color: #f8af42;
  color: #212529;
  font-weight: bold;
}
.ProgrammationsList .ReactTable .rt-td .hour {
  float: left;
  min-width: 40px;
  margin: 0 3px 3px 0;
  padding: 5px 10px;
  border: 1px solid #212529;
  background-color: #f8af42;
  color: #212529;
  font-weight: bold;
}
.ProgrammationsList .ReactTable .rt-td .dates .date {
  display: block;
}

.dark-app .ProgrammationsList .day {
  background-color: #FF9900 !important;
}
.dark-app .ProgrammationsList .hour {
  background-color: #FF9900 !important;
}

.TickettypeFieldForm .input-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}
.TickettypeFieldForm .input-wrapper input {
  flex: 0.3;
}
.TickettypeFieldForm .input-wrapper label {
  flex: 1;
}

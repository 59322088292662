.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
  min-width: 95vh;
}

.PlaceMap {
  /* Zone Creation */
  /* Seat Detail */
}
.PlaceMap h3 {
  border-bottom: 1px solid #454545;
  margin-bottom: 10px;
}
.PlaceMap .container {
  overflow-x: scroll;
  overflow-y: scroll;
}
.PlaceMap .rows {
  display: flex;
  flex-direction: row;
}
.PlaceMap .rowTitle {
  min-width: 35px;
  height: 20px;
}
.PlaceMap .categoryOptionBg {
  display: flex;
  flex-direction: row;
}
.PlaceMap .categoryTitle {
  height: 20px;
  min-width: 35px;
}
.PlaceMap .categoryOption {
  padding: 5px;
  border-radius: 5px;
  background-color: "gray";
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
}
.PlaceMap .seatTypeTitle {
  margin-bottom: 5px;
  margin-top: 5px;
}
.PlaceMap .seat {
  border: 1px solid red;
  background-color: transparent;
  color: #000000;
  width: 20px;
  height: 20px;
  font-size: 10px;
  text-align: center;
  padding: 0;
}
.PlaceMap .zoneOptionMargin {
  margin-right: 5px;
  margin-bottom: 5px;
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.EventsField .item img.image {
  width: 50px !important;
  height: 35px;
  object-fit: cover;
}

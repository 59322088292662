/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.Changelog {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  z-index: 1000;
  background-color: #f8f9fa;
  box-shadow: inset 7px 0 9px -7px #1A1A1A;
  overflow-y: scroll;
  display: none;
}
.Changelog .hover-version:hover {
  cursor: pointer;
  background-color: #f8af42 !important;
}
.Changelog .hover-version:focus {
  outline: none;
  box-shadow: none;
}
.Changelog.expandedLog {
  animation: expandLog 0.2s linear 0s normal forwards;
  padding: 20px;
}
.Changelog.reducedLog {
  animation: reduceLog 0.2s linear 0s normal forwards;
  padding: 0;
}
@media (max-width: 767px) {
  .Changelog {
    box-shadow: none !important;
  }
  .Changelog.expandedLog {
    animation: expandResponsiveLog 0.2s linear 0s normal forwards;
    padding: 20px;
  }
  .Changelog.reducedLog {
    animation: reduceResponsiveLog 0.2s linear 0s normal forwards;
    padding: 0;
  }
}
@keyframes expandLog {
  0% {
    width: 0px;
  }
  100% {
    width: 450px;
  }
}
@keyframes reduceLog {
  0% {
    width: 450px;
  }
  100% {
    width: 0px;
  }
}
@keyframes expandResponsiveLog {
  0% {
    width: 0px;
  }
  100% {
    width: 100vw;
  }
}
@keyframes reduceResponsiveLog {
  0% {
    width: 100vw;
  }
  100% {
    width: 0px;
  }
}

.dark-app .Changelog {
  background-color: #1A1A1A;
  box-shadow: inset 7px 0 9px -7px #f8f9fa;
  color: #FFFFFF;
}
.dark-app .Changelog * {
  color: #FFFFFF;
}

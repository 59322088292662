/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.PricingForm .tab-pane {
  background-color: #FFFFFF !important;
  padding: 2px;
}
.PricingForm .lang-switcher.active {
  background-color: #FF9900 !important;
}
.PricingForm Label {
  color: #212529;
}

.dark-app .PricingForm .tab-pane {
  background-color: #2A2A2A !important;
  border-bottom: none !important;
  border-left: none !important;
  border-right: none !important;
}
.dark-app .PricingForm .lang-switcher.active {
  background-color: #FF9900 !important;
}
.dark-app .PricingForm Label {
  color: #FFFFFF;
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.MainContent {
  position: relative;
  z-index: 98 !important;
  padding: 68px 0 0 0;
  height: 100%;
  border-radius: 4px;
  moz-border-radius: 4px;
  webkit-border-radius: 4px;
}
.MainContent .FlashInfo {
  margin-bottom: 10px;
}

.expandedMainContent {
  animation: expandMainContent 0.2s linear 0s normal forwards;
}

.reducedMainContent {
  animation: reduceMainContent 0.2s linear 0s normal forwards;
}

@keyframes expandMainContent {
  0% {
    padding-left: 45px;
  }
  100% {
    padding-left: 220px;
  }
}
@keyframes reduceMainContent {
  0% {
    padding-left: 220px;
  }
  100% {
    padding-left: 45px;
  }
}
@media (max-width: 767px) {
  .MainContent {
    padding-top: 50px;
  }
}

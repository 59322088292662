/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.ProgressBarScreenings {
  display: flex;
  flex-direction: column;
}
.ProgressBarScreenings .FillBar {
  flex: 1;
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.WidgetSharedSettings .settingsFormInput {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.WidgetSharedSettings .settingsFormLabel {
  height: 40px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.WidgetSharedSettings .settingsFormLabel div:first-of-type {
  width: 70%;
}
.WidgetSharedSettings .settingsLabelRight {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.WidgetSharedSettings .settingsInput {
  min-width: 0;
  width: 100%;
}

.SectorsField .card-body tbody tr {
  position: relative;
  cursor: pointer;
  padding: 5px;
  display: block;
  border: 1px solid #FFFFFF;
  border-radius: 4px;
  margin-top: 4px;
}
.SectorsField .card-body tbody tr .fa.fa-long-arrow-right {
  position: absolute;
  top: 8px;
  right: 15px;
}
.SectorsField .card-body tbody tr .fa.fa-long-arrow-left {
  position: absolute;
  top: 8px;
  left: 15px;
}
.SectorsField .card-body tbody tr.add:hover {
  background: #065461;
  color: #FFFFFF;
}
.SectorsField .card-body tbody tr.remove {
  padding-left: 45px;
}
.SectorsField .card-body tbody tr.remove:hover {
  background: #6f0913;
  color: #FFFFFF;
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.HoursField .row {
  margin-bottom: 6px !important;
}
.HoursField .row .times a {
  border: 1px solid #212529;
  padding: 5px 14px;
  width: 36px;
  box-sizing: content-box;
  background: #f8af42;
  color: #212529;
  border-radius: 4px;
  moz-border-radius: 4px;
  webkit-border-radius: 4px;
  margin: 0 5px 5px 0;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
}
.HoursField .row .times a:hover {
  background-color: #6f0913;
  color: #FFFFFF;
}
.HoursField .row .times a:hover .time {
  display: none;
}
.HoursField .row .times a:hover .icon {
  display: inherit;
}
.HoursField .row .times a .icon {
  display: none;
}
.HoursField .row .times .lang-switcher {
  background-color: #2A2A2A;
  color: #FFFFFF;
  border-top: 1px solid #C8C9CA;
  border-left: 1px solid #C8C9CA;
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  cursor: pointer;
  padding: 0.2rem 1rem;
}
.HoursField .row .times .lang-switcher.active {
  background-color: #FF9900;
  color: #212529;
}

.dark-app .HoursField .row {
  margin-bottom: 6px !important;
}
.dark-app .HoursField .row .times a {
  background: #FF9900;
}

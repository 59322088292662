/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.EmailLayouts .LanguageSelector {
  margin-left: 10px;
}
.EmailLayouts .topButtonsWrapper {
  padding: 7px;
  margin-bottom: 5px;
  border-radius: 4px 4px 0 0;
  background-color: #151515;
  width: calc(100% - 20px);
}
.EmailLayouts .dataTestButtonsWrapper {
  padding: 7px;
  margin-bottom: 5px;
  background-color: #151515;
  width: calc(100% - 20px);
}
.EmailLayouts .dataTestButtonsWrapper label {
  margin: 0 5px;
}
.EmailLayouts .chrome-picker {
  max-width: 100%;
}
.EmailLayouts .previewWrapper {
  padding: 10px;
  background-color: #151515;
  width: calc(100% - 20px);
  max-height: calc(100vh - 140px);
  overflow: auto;
  border-radius: 0 0 4px 4px;
}
.EmailLayouts .previewWrapper .preview {
  display: inline-block;
  background-color: #151515;
  width: 100%;
  min-height: 300px;
}
.EmailLayouts .previewWrapper .preview .contentWrapper {
  width: 100%;
  height: 100%;
  max-width: 800px;
  margin: 0 auto;
  background-color: #FFFFFF;
  position: relative;
}
.EmailLayouts .previewWrapper .preview .contentWrapper p {
  line-height: inherit;
}
.EmailLayouts .widgetBtn {
  width: calc(100% - 10px);
  min-height: 40px;
  margin-right: 20px;
  margin-bottom: 10px;
  background-color: #ffad33;
  color: #212529;
  position: relative;
}
.EmailLayouts .widgetBtn:hover, .EmailLayouts .widgetBtn:focus, .EmailLayouts .widgetBtn:active {
  background-color: #FF9900;
  color: #212529;
}
.EmailLayouts .widgetBtn:nth-child(2n) {
  margin-right: 0;
}
.EmailLayouts .widgetBtn i {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translate(0, -50%);
  font-size: 20px;
}
.EmailLayouts .imageOption {
  width: 100%;
  height: 120px;
  background-color: #252525;
}
.EmailLayouts .imageOption img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.EmailLayouts .imageOption .file_input {
  width: 100%;
  height: 120px;
  opacity: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}
.EmailLayouts .widgetsLists {
  max-height: 300px;
  overflow: auto;
}

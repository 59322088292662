/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.Wysiwyg .DraftEditor-root {
  z-index: unset;
}
.Wysiwyg .DraftEditor-root .public-DraftEditor-content {
  min-height: 10rem;
}
.Wysiwyg select {
  color: black !important;
}
.Wysiwyg select option {
  color: black !important;
}

.InputPopover__root___3Hpj9 {
  color: black;
  width: 450px;
}

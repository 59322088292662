.Stats .ReactTable {
  width: 100% !important;
}
@media (max-width: 1468px) {
  .Stats .ReactTable {
    width: 100% !important;
  }
  .Stats .ReactTable .btn {
    font-size: 75% !important;
  }
  .Stats .ReactTable .expander {
    display: none;
  }
  .Stats .ReactTable .rt-tbody,
  .Stats .ReactTable .rt-thead,
  .Stats .ReactTable .rt-tfoot {
    min-width: 100% !important;
    overflow-y: hidden;
    overflow-x: hidden;
  }
  .Stats .ReactTable .rt-tr {
    width: 100% !important;
  }
  .Stats .ReactTable .rt-td,
  .Stats .ReactTable .rt-th {
    display: flex;
    padding: 3px 0 3px 0;
    align-items: center;
    justify-content: center;
  }
  .Stats .ReactTable .rt-td {
    word-break: break-word !important;
    white-space: break-spaces;
    overflow: visible;
  }
}
@media (max-width: 767px) {
  .Stats {
    width: 100% !important;
    font-size: 2vw;
  }
  .Stats .btn {
    font-size: 3vw !important;
  }
}
@media print {
  .Stats .total-entries-col {
    display: flex !important;
    justify-content: end;
    align-items: end;
  }
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.Clock .mainDisplay {
  width: 100%;
  text-align: center;
}
.Clock .mainDisplay .time {
  font-size: 4.6vw;
  line-height: 4vw;
}
.Clock .mainDisplay .date {
  font-style: italic;
  font-size: 1.3vw;
}
.Clock .settingsFormInput {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.Clock .settingsFormLabel {
  height: 40px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.Clock .settingsFormLabel div:first-of-type {
  width: 70%;
}
.Clock .settingsLabelRight {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.Clock .settingsInput {
  min-width: 0;
  width: 250px;
}
.Clock .submitButtons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.Clock .submitButtons Button {
  width: 6vw;
  font-size: 0.75vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .Clock .time {
    font-size: 7vw !important;
  }
  .Clock .date {
    font-size: 4vw !important;
  }
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.ArticleExtras .extra.jumbotron {
  border: 1px solid #454545;
  padding: 0 !important;
  border-radius: 4px;
  moz-border-radius: 4px;
  webkit-border-radius: 4px;
}
.ArticleExtras .extra.jumbotron .extra-header {
  border-radius: 4px 4px 0 0;
  moz-border-radius: 4px 4px 0 0;
  webkit-border-radius: 4px 4px 0 0;
  background: #171616;
  padding: 10px;
  cursor: pointer;
}
.ArticleExtras .extra.jumbotron .extra-body {
  padding: 10px;
  padding-bottom: 0;
}
.ArticleExtras .extra.jumbotron .extra-body .extra-choice .TranslatableField {
  display: inline-block;
  width: calc(100% - 40px);
  margin-left: 5px;
}
.ArticleExtras .extra.jumbotron .extra-footer {
  text-align: center;
  border-top: 1px solid #454545;
  padding: 10px;
  padding-bottom: 0;
}
.ArticleExtras .extra.jumbotron.active .extra-header {
  border-bottom: 1px solid #454545;
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.PhasesList .ReactTable .rt-td {
  min-height: 60px;
}
.PhasesList .ReactTable .rt-td .name_cell a {
  color: #212529 !important;
}

.dark-app .PhasesList .ReactTable .rt-td .name_cell a {
  color: #FFFFFF !important;
}

.VideoField .select-trailer-type {
  max-width: 100px;
}
.VideoField .select-trailer-type.mobile-select-trailer {
  max-width: calc(100% - 40px) !important;
}
.VideoField .trailer {
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 3px 0 0 3px;
}
.VideoField .trailer.youtube {
  background-color: red;
}
.VideoField .trailer.vimeo {
  background-color: #00BFFF;
}
.VideoField .trailer.other {
  background-color: silver;
}

.EventForm .PageTitle .float-left {
  display: none;
}
.EventForm .poster {
  max-width: 100%;
  max-height: 300px;
}
.EventForm input[name=new_section] {
  display: inline-block;
  width: auto;
  margin: 0 0.2rem 0 2rem;
  padding-top: 0;
  padding-bottom: 6px;
}
.EventForm .section-wrapper {
  position: relative;
  display: inline-block;
  border: 1px solid #359ec7;
  padding: 0.3rem 1rem;
  border-radius: 4px;
  margin-right: 0.4rem;
  background-color: #8adcfd;
  color: #FFF;
}
.EventForm .section-wrapper .remove-item {
  color: #000;
  position: absolute;
  top: 0rem;
  left: auto;
  right: 0.1rem;
  font-size: 0.8rem;
  cursor: pointer;
}
.EventForm .remove-wrapper .remove-item {
  color: #AAA;
  font-size: 2rem;
  position: absolute;
  top: 0;
  left: auto;
  right: 0;
  cursor: pointer;
  border: 1px solid #AAA;
  padding: 0.3rem 0.5rem;
  border-radius: 4px;
  display: none;
}
.EventForm .remove-wrapper:hover .remove-item {
  display: inherit;
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.WysiwygField .DraftEditor-root {
  z-index: unset;
}

.InputPopover__root___3Hpj9 {
  color: black;
  width: 450px;
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.ScreeningsList .actions-cell {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ScreeningsList .hours-wrapper {
  width: 75%;
  text-align-last: justify;
}
.ScreeningsList .ReactTable .rt-td {
  min-height: 60px;
}
.ScreeningsList .ReactTable .rt-td .screening_cell .card-body {
  padding: 0;
}

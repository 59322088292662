/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.UserEdit .TasksList {
  background-color: #1A1A1A;
  padding: 10px;
}

@media print {
  .UserEdit .card-title {
    color: #000000 !important;
  }
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.TktInstanceAbilitiesTemplate .align-center {
  text-align: center;
}
.TktInstanceAbilitiesTemplate .card {
  border: solid 0.5px #212529 !important;
}
.TktInstanceAbilitiesTemplate .card-header {
  border-bottom: solid 0.5px #212529 !important;
}
.TktInstanceAbilitiesTemplate .checkAbility {
  height: 25px !important;
  width: 25px;
}
.TktInstanceAbilitiesTemplate table {
  width: 100%;
}
.TktInstanceAbilitiesTemplate table .checkAbility {
  height: 25px !important;
  width: 25px;
}
.TktInstanceAbilitiesTemplate table .tableTitle {
  font-size: large;
  font-weight: bold;
}
.TktInstanceAbilitiesTemplate table th,
.TktInstanceAbilitiesTemplate table td {
  border: solid;
  border-width: 0.5px;
  border-color: #757575;
  padding: 5px !important;
  background-color: #f8f9fa;
}
.TktInstanceAbilitiesTemplate table .moduleTitle {
  width: 30%;
}
.TktInstanceAbilitiesTemplate table .checkBoxModule {
  width: 9%;
  text-align: center;
  vertical-align: middle;
}
.TktInstanceAbilitiesTemplate table .checkBoxModule input {
  vertical-align: middle;
  display: inline-block;
}

.dark-app .TktInstanceAbilitiesTemplate .card {
  border: solid 0.5px #C8C9CA !important;
}
.dark-app .TktInstanceAbilitiesTemplate .card-header {
  border-bottom: solid 0.5px #C8C9CA !important;
}
.dark-app .TktInstanceAbilitiesTemplate th,
.dark-app .TktInstanceAbilitiesTemplate td {
  background-color: #2A2A2A;
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.TasksListResume .rt-tbody .rt-tr .rt-td:not(:first-of-type), .TasksListResume .rt-tfoot .rt-tr .rt-td:not(:first-of-type) {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

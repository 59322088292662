/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
@media screen and (max-width: 767px) {
  .WidgetSeparator {
    display: none;
  }
}
.WidgetSeparator .settingsFormInput {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.WidgetSeparator .settingsFormLabel {
  height: 40px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.WidgetSeparator .settingsInput {
  min-width: 0;
  width: 250px;
}
.WidgetSeparator .submitButtons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.WidgetSeparator .submitButtons Button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6vw;
  font-size: 0.75vw;
}

.dark-app .WidgetSeparator {
  background-color: #171616;
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.FormLayouts .LanguageSelector {
  margin-left: 10px;
}
.FormLayouts .topButtonsWrapper {
  padding: 7px;
  margin-bottom: 5px;
  border-radius: 4px 4px 0 0;
  background-color: #151515;
  width: calc(100% - 20px);
}
.FormLayouts .dataTestButtonsWrapper {
  padding: 7px;
  margin-bottom: 5px;
  background-color: #151515;
  width: calc(100% - 20px);
}
.FormLayouts .dataTestButtonsWrapper label {
  margin: 0 5px;
}
.FormLayouts .chrome-picker {
  max-width: 100%;
}
.FormLayouts .previewWrapper {
  padding: 10px;
  background-color: #151515;
  width: calc(100% - 20px);
  max-height: calc(100vh - 140px);
  overflow: auto;
  border-radius: 0 0 4px 4px;
}
.FormLayouts .previewWrapper .preview {
  display: inline-block;
  background-color: #151515;
  width: 100%;
  min-height: 300px;
}
.FormLayouts .previewWrapper .preview .contentWrapper {
  width: 100%;
  height: 100%;
  max-width: 800px;
  margin: 0 auto 230px;
  box-shadow: 0 0 3px 1px #454545;
  border-radius: 4px;
  position: relative;
}
.FormLayouts .previewWrapper .preview .contentWrapper .required:after {
  content: " *";
  color: red;
}
.FormLayouts .previewWrapper .preview .contentWrapper .listFieldChoice {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.FormLayouts .previewWrapper .preview .contentWrapper .listFieldChoice > input {
  flex: auto;
  max-width: 40px;
}
.FormLayouts .previewWrapper .preview .contentWrapper .listFieldChoice > label {
  flex: 1;
}
.FormLayouts .previewWrapper .preview .contentWrapper p {
  line-height: inherit;
}
.FormLayouts .closeButtonWrapper {
  position: relative;
}
.FormLayouts .closeButtonWrapper .closeButton {
  position: absolute;
  z-index: 100;
  top: -10px;
  right: -10px;
}
.FormLayouts .row.toggleRow {
  padding-top: 10px;
}
.FormLayouts .row.toggleRow > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.FormLayouts .fieldBtn {
  width: calc(100% - 10px);
  min-height: 40px;
  margin-right: 20px;
  margin-bottom: 10px;
  background-color: #ffad33;
  color: #212529;
  position: relative;
}
.FormLayouts .fieldBtn:hover, .FormLayouts .fieldBtn:focus, .FormLayouts .fieldBtn:active {
  background-color: #FF9900;
  color: #212529;
}
.FormLayouts .fieldBtn:nth-child(2n) {
  margin-right: 0;
}
.FormLayouts .fieldBtn i {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translate(0, -50%);
  font-size: 20px;
}
.FormLayouts .imageOption {
  width: 100%;
  height: 120px;
  background-color: #252525;
}
.FormLayouts .imageOption img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.FormLayouts .imageOption .file_input {
  width: 100%;
  height: 120px;
  opacity: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}
.FormLayouts .fieldsLists {
  max-height: 300px;
  overflow: auto;
}

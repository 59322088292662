/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.ConfigSettings {
  background: #1A1A1A;
  padding: 20px;
  border-radius: 4px;
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.PdfLayoutMappings .inner-card {
  background-color: #f8f9fa !important;
}

.dark-app .PdfLayoutMappings .inner-card {
  background-color: #757575 !important;
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.ApplicationForm .wrapper {
  padding-top: 20px;
}
.ApplicationForm .wrapper .required:after {
  content: " *";
  color: red;
}

/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.HostResume {
  max-width: 300px;
}
.HostResume .card {
  border: 1px solid #0E0E0E;
}
.HostResume .card .card-header .card-title {
  text-align: center !important;
  margin-bottom: 0;
}
.HostResume .card > img {
  max-width: 100%;
  border: 1px solid #0E0E0E;
  border-radius: 0 !important;
  moz-border-radius: 0 !important;
  webkit-border-radius: 0 !important;
}
.HostResume .card > img.connected {
  background: #88ff98;
}
.HostResume .card > img.disconnected {
  background: #ff8888;
}
.HostResume .card .card-body .badge {
  font-weight: normal;
  font-style: italic;
}

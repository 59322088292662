/* Text colors */
/* Background colors */
/* Status colors */
/* Override bootstrap variables */
/* Widget height */
.ArticlesTable .top-buttons-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}
.ArticlesTable .sortable-grid {
  background: #101010;
  margin: 1rem 0;
  padding-bottom: 1rem;
}
.ArticlesTable .sortable-grid .drag-handle {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  color: #FFFFFF;
  font-size: 16px;
  cursor: pointer;
}
.ArticlesTable .ReactTable .rt-td {
  min-height: 65px;
}
.ArticlesTable .ReactTable .rt-td .article_cell .card-body {
  padding: 0;
}
.ArticlesTable .ReactTable .rt-td .article_cell .card-body .title-wrapper {
  background: rgba(0, 0, 0, 0.5);
  height: 57px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
}
.ArticlesTable .ReactTable .rt-td .article_cell .card-body .title-wrapper .card-title {
  width: 94%;
  white-space: normal;
  margin-bottom: 0;
  border-bottom: none;
  font-size: 16px;
  font-weight: bold;
}
.ArticlesTable .ReactTable .rt-td .article_cell .card-body .title-wrapper .card-title small {
  display: block;
}
.ArticlesTable .card.article-card {
  width: 100%;
  max-width: 130px;
  height: 100%;
  max-height: 130px;
}
.ArticlesTable .card.article-card .card-body {
  padding: 0;
  height: 100%;
}
.ArticlesTable .card.article-card .card-body .title-wrapper {
  background: rgba(0, 0, 0, 0.5);
  min-height: 80px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
}
.ArticlesTable .card.article-card .card-body .title-wrapper .card-title {
  width: calc(100% - 25px);
  padding-top: 25px !important;
  white-space: normal !important;
  margin: 0 !important;
  border: none !important;
  font-size: 14px !important;
  font-weight: 700;
  display: block;
  width: calc(100% - 10px);
  margin: 0 auto;
  overflow: hidden;
  text-align: center;
}
.ArticlesTable .card.article-card .card-body .title-wrapper .card-title small {
  display: block;
}

body > .col-sm-2 .card.article-card {
  background-color: rgba(0, 0, 0, 0);
  width: 130px !important;
  height: 80px !important;
}
body > .col-sm-2 .card.article-card .drag-handle {
  color: #FFFFFF;
}
body > .col-sm-2 .card.article-card .title-wrapper {
  display: none;
}
